import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { GetPlayers } from "../actions/PlayerActions";
import { selectAuth } from "../features/user/authSlice";
import { useAppSelector } from "../store/hooks";
import { AppDispatch } from "../store/store";
import Arlanda from "../assets/arlanda.png";

export const UserHomePage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const auth = useAppSelector(selectAuth);
  useEffect(() => {
    GetPlayers(dispatch);
  }, []);


  return (
    <div className="content-box">
      <h1>Hej, {auth.user?.UserName}!</h1>
      <h2>VIKTIG INFO!</h2>
      <div className="flight-info">
        <p>
          Snart är det dags! Här följer viktig information om den inledande delen av resan:
        </p>
        <p className="flight-info-text">
          <span className="bold">Plats:</span> Arlanda terminal 5 (se karta)
        </p>
        <p className="flight-info-text">
        <span className="bold">Tid:</span> 05:00
        </p>
        <p className="flight-info-text">
        <span className="bold">Bagage:</span> Handbagage, max 8kg + liten väska under sätet
        </p>
      </div>
      <div className="arlanda-image">
        <img src={Arlanda} />
      </div>
    </div>
  );
};