import { App, Button, Drawer, Layout, Menu } from "antd";
import { Content, Header } from "antd/es/layout/layout";
import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router";
import { iUser, logoutAsync, selectAuth } from "../../features/user/authSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import BackGroundImage from "../BackgroundImage";
import { MenuOutlined } from '@ant-design/icons';

export const AdminLayout = ({ UserName }: iUser) => {
  const auth = useAppSelector(selectAuth);
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [current, setCurrent] = useState(
    location.pathname === "/" || location.pathname === ""
      ? "/"
      : location.pathname
  );

  useEffect(() => {
    if (location) {
      if (current !== location.pathname) {
        setCurrent(location.pathname);
      }
    }
  }, [location]);

  const handleClick = (key: string) => {
    onClose();
    navigate(key);
  };

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };
  
  return (
    <App>
      <Layout className="layout">
        <Header style={{ display: "flex", alignItems: "center" }} className={auth.user ? "header with-logo" : "header"}>
        {auth.user && <h3 className="header-logo">Uppesittarresan.</h3>}
        <Button
            type="primary"
            icon={<MenuOutlined />}
            onClick={showDrawer}
            style={{ position: 'fixed', top: 16, left: 16, zIndex: 1000 }}
            className="menu-button"
          />
          <Drawer
            // title="Menu"
            placement="right"
            closable={true}
            onClose={onClose}
            visible={visible}
            className="menu-drawer"
          >
            <Menu
              mode="inline"
              defaultSelectedKeys={["/"]}
              selectedKeys={[current]}
              style={{ minWidth: "500px" }}
              items={[
                {
                  key: "/",
                  label: "Hem",
                  onClick: (e) => {
                    handleClick(e.key);
                  },
                },
                {
                  key: "/maffia",
                  label: "Maffia",
                  onClick: (e) => {
                    handleClick(e.key);
                  },
                },
                {
                  key: "/create-user",
                  label: "Skapa användare",
                  onClick: (e) => {
                    handleClick(e.key);
                  }
                },
                {
                  key: "/create-mission-template",
                  label: "Skapa uppdragsmall",
                  onClick: (e) => {
                    handleClick(e.key);
                  }
                },
                {
                  key: "/assign-mission",
                  label: "Tilldela uppdrag",
                  onClick: (e) => {
                    handleClick(e.key);
                  }
                },
                {
                  key: "/correct-missions",
                  label: "Rätta uppdrag",
                  onClick: (e) => {
                    handleClick(e.key);
                  }
                },
                {
                  key: "/packing-list",
                  label: "Packlista",
                  onClick: (e) => {
                    handleClick(e.key);
                  }
                },
                {
                  key: "/faq",
                  label: "Frågor & svar",
                  onClick: (e) => {
                    handleClick(e.key);
                  }
                },
                {
                  key: "/scores",
                  label: "Poängställning",
                  onClick: (e) => {
                    handleClick(e.key);
                  }
                },
                {
                  key: "/logout",
                  label: "Logout",
                  onClick: () => {
                    dispatch(logoutAsync());
                    handleClick("/");
                  },
                },
              ]}
            />
          </Drawer>
        </Header>
        <Content style={{ padding: "0 15px", minHeight: "400px" }}>
          <div className="content">
            <Outlet />
          </div>
        </Content>
      <BackGroundImage />
      </Layout>
    </App>
  );
};