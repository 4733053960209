import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import './Override.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { AxiosApiInterceptor } from './store/axiosApiInterceptor';
import { store } from './store/store';
import ConfigProvider from 'antd/es/config-provider';
import './App.scss';
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <Provider store={store}>
      <BrowserRouter>
        <AxiosApiInterceptor />
          <App />
      </BrowserRouter>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
