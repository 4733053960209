import {
    configureStore,
    ThunkAction,
    Action,
    combineReducers,
  } from "@reduxjs/toolkit";
  import authReducer from "../features/user/authSlice";
  import storage from "redux-persist/lib/storage"; 
  import { persistReducer, persistStore } from "redux-persist";
  import thunk from "redux-thunk";
  import playersReducer from "../features/players/playersSlice";
  import currentPlayerReducer from "../features/players/currentPlayerSlice";
  
  const authPersistConfig = {
    key: "auth",
    storage:storage,
  };
  const playerPersistConfig = {
    key: "players",
    storage:storage,
  };
  
  const rootReducer = combineReducers({
    auth: persistReducer(authPersistConfig, authReducer),
    players: persistReducer(playerPersistConfig, playersReducer)
  });
  
  export const store = configureStore({
    reducer: {
      rootReducer,
      currentPlayer: currentPlayerReducer
    },
    devTools: process.env.NODE_ENV !== "production",
    middleware: [thunk],
  });
  
  export const persister = persistStore(store);
  
  export type AppDispatch = typeof store.dispatch;
  export type RootState = ReturnType<typeof store.getState>;
  export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
  >;