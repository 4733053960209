import { Checkbox, Form, Input, message, Select, Table, TabsProps } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { ProgressTypes } from "antd/es/progress/progress";
import { useEffect, useState } from "react";
import { CheckCommonItem, CheckItem, GetPackingList } from "../../actions/PackingListActions";
import { AddSecretMission, GetSecretMissions } from "../../actions/SecretMissionActions";
import { selectPlayers } from "../../features/players/playersSlice";
import { resetLoading, selectAuth, setLoading } from "../../features/user/authSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { PackingListItems, SecretMission } from "../../store/types";

const PackingListPage = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const auth = useAppSelector(selectAuth);
  const players = useAppSelector(selectPlayers);
  const dispatch = useAppDispatch();
  const [secretMissions, setSecretMissions] = useState<SecretMission[]>();
  const [packingList, setPackingList] = useState<PackingListItems[]>();
  const [commonPackingList, setCommonPackingList] = useState<PackingListItems[]>();
  const [tabItems, setTabItems] = useState<TabsProps["items"]>([]);

  const PackingList = async () => {
    dispatch(setLoading());
    const data = await GetPackingList();
    dispatch(resetLoading());
    if (data) {
      setPackingList(data.filter(x => !x.isCommonItem));
      setCommonPackingList(data.filter(x => x.isCommonItem));
    }
  }
  const showSuccess = () => {
    messageApi.open({
      type: 'success',
      content: 'Du har utfört ett hemligt uppdrag! Poäng och ledtråd kommer snart!',
      duration: 5,
    });
  };

  const SecretMissions = async () => {
    dispatch(setLoading());
    const secretMissions = await GetSecretMissions();
    dispatch(resetLoading());
    if (secretMissions) {
      setSecretMissions(secretMissions.data);
    }
  }

  const handleCommonItemChecked = async (props: CheckboxChangeEvent, item: any) => {
    const request = {
      itemId: item.id,
      checked: props.target.checked,
      checkedBy: auth.user!.PleyerId
    }
    dispatch(setLoading());
    const response = await CheckCommonItem(request);
    dispatch(resetLoading());
    if (response) {
      PackingList();
    }
  };
  
  const handleItemChecked = async (props: CheckboxChangeEvent, item: any) => {
    const request = {
      itemId: item.id,
      checked: props.target.checked,
      checkedBy: auth.user!.PleyerId
    }
    dispatch(setLoading());
    const response = await CheckItem(request);
    dispatch(resetLoading());
    if (response) {
      PackingList();
    }
  };

  const columns = [
    {
      title: "Grejer",
      dataIndex: "item",
      key: "id"
    },
    {
      title: "Packat",
      dataIndex: "checkedBy",
      key: "id",
      className: "packing-list-checkbox",
      render: (checked: boolean, record: PackingListItems) => <Checkbox checked={record.checkedBy.includes(parseInt(auth.user!.PleyerId))} onChange={(e) => handleItemChecked(e, record)}  />
    }
  ]

  const commonColumns = [
    {
      title: "Lista",
      dataIndex: "item",
      key: "id"
    },
    {
      title: "Jag tar med",
      dataIndex: "checkedBy",
      key: "id",
      className: "packing-list-checkbox",
      onCell: (record: PackingListItems) => {
        return {
          className: record.commonItemCheckedBy !== null
            ? "packing-list-checkbox occupied"
            : "packing-list-checkbox",
        };
      },
      // className: "packing-list-checkbox",
      render: (item: PackingListItems, record: any) => {
        return (
          <div className="checkbox-wrapper">
            {record.commonItemCheckedBy !== null && <p>{players.players.find(x => x.id! === record.commonItemCheckedBy)?.username}</p>}
            <Checkbox className={record.commonItemCheckedBy !== null ? "packing-list-checkbox occupied" : "packing-list-checkbox"} checked={record.commonItemCheckedBy !== null} disabled={record.commonItemCheckedBy !== null && record.commonItemCheckedBy !== parseInt(auth.user!.PleyerId)} onChange={(e) => handleCommonItemChecked(e, record)} />
          </div>

        )
    }
    }
  ]

  useEffect(() => {
    SecretMissions();
    PackingList();
  }, []);

  // useEffect(() => {
  //   packingList && SetPackingListTable();
  // }, [packingList]);
  const submitSecretMission = async (key: string) => {
    const request: SecretMission = {
      missionKey: key,
      foundBy: parseInt(auth.user!.PleyerId),
      isCorrected: false
    }
    dispatch(setLoading());

    if (auth.user) {
      const data = await AddSecretMission(request);
      dispatch(resetLoading());
      if (data?.data) {
        showSuccess();
        SecretMissions();
      }
    } else {
      dispatch(resetLoading());
    }
  };

  return (
    <div className="content-box">
      {contextHolder}
      <div className="packing-list">
        <h1>Packlista</h1>
        <p>Här är en packlista att gå efter när det är dags! Sidan innehåller två sorters listor: En för saker alla bör packa och en för saker som kan behövas men som inte alla behöver ta med. Hittar du något i den undre listan som du kan tänka dig att ta med, kryssa då i det så att alla andra ser.</p>
        <h2>Din packlista</h2>
        <Table dataSource={packingList} columns={columns} pagination={false} rootClassName="packing-list-table" />
        <h2>Gemensam packlista</h2>
        <Table dataSource={commonPackingList} columns={commonColumns} pagination={false} rootClassName="packing-list-table" />
        {/* {
          auth.user && auth.user.RoleClaim.includes("HasRoleAdd") && 
            <Form>
              <Form.Item>
                <Input />
              </Form.Item>
              <Form.Item>
                <Select />
              </Form.Item>
            </Form>
          
        } */}
      </div>
      {secretMissions ?
        secretMissions.find(x => x.missionKey === "scroll" && x.foundBy === parseInt(auth.user!.PleyerId)) ? <></> :
          <div className="secret-scroll">
            <p className="secret-2" onClick={(e) => { submitSecretMission("scroll") }}>DU HITTADE MIG!</p>
          </div>
        :
        <div className="secret-scroll">
          <p className="secret-2" onClick={(e) => { submitSecretMission("scroll") }}>DU HITTADE MIG!</p>
        </div>
      }
    </div>
  )
};

export default PackingListPage;