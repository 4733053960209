import { Button, Form, Input } from "antd";
import { parse } from "path";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { parseIsolatedEntityName, setOriginalNode } from "typescript";
import { ConfirmRole, CreateGame, EndGame, GetGameState, GetRole, JoinGame, StartGame, Assassinate, CheckResult, CheckConfirm } from "../../actions/MaffiaActions";
import { resetLoading, selectAuth, setLoading } from "../../features/user/authSlice";
import { useAppSelector } from "../../store/hooks";
import { AppDispatch } from "../../store/store";
import { CrownFilled } from '@ant-design/icons';
import { MaffiaGame } from "./MaffiaGame";

export interface IGameState {
  gameMaster: number;
  players: MafiaPlayer[];
}

export interface MafiaPlayer {
  id: number;
  name: string;
}
export interface ICreateGameValues {
  gameMaster: number;
  playerName: string;
}
export interface IJoinGameValues {
  playerId: number;
  playerName: string;
}
export const Maffia = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [gamePhase, setGamePhase] = useState("");
  const [gameState, setGameState] = useState<IGameState>();
  const [isLoading, setIsLoading] = useState(true);
  const [isGameMaster, setIsGameMaster] = useState(false);
  const [hasJoined, setHasJoined] = useState(false);
  const [roleConfirmed, setRoleConfirmed] = useState(false);
  const [checkConfirmed, setCheckConfirmed] = useState(false);
  const [role, setRole] = useState<string>();
  const [check, setChecked] = useState({ target: { id: 999, name: "pelle" }, isMafia: false });
  const auth = useAppSelector(selectAuth);

  const PingGameState = async () => {
    const game = await GetGameState();
    const role = await GetRole(parseInt(auth.user!.PleyerId));
    setGameState(game.data);
    if (game.data.currentPhase !== gamePhase) {
      setGamePhase(game.data.currentPhase);
      if (role) {
        setRole(role.data === "Lakare" ? "Läkare" : role.data);
      }
    }
    // if (game.data.currentPhase === "ShowRoles" && !roleConfirmed) {
    //   await handleConfirmRole();
    // }
  }

  const handleGetRole = async () => {
    const role = await GetRole(parseInt(auth.user!.PleyerId));
    if (role)
     setRole(role.data === "Lakare" ? "Läkare" : role.data);
  }

  const handleCheckResult = async () => {
    const result = await CheckResult();
    console.log("RESULT", result);
    setChecked(result.data);
  }

  const handleCreateGame = async (values: ICreateGameValues) => {
    dispatch(setLoading());
    setIsGameMaster(true);
    const request: ICreateGameValues = {
      gameMaster: parseInt(auth.user!.PleyerId),
      playerName: values.playerName
    }
    const game = await CreateGame(request);
    PingGameState();
    setHasJoined(true);
  }

  const handleJoinGame = async (values: IJoinGameValues) => {
    dispatch(setLoading());
    const request: IJoinGameValues = {
      playerId: parseInt(auth.user!.PleyerId),
      playerName: values.playerName
    }
    const join = await JoinGame(request);
  }
  useEffect(() => {
    const intervalId = setInterval(PingGameState, 5000); // Store the interval ID

    return () => {
      clearInterval(intervalId); // Clear the interval when the component unmounts or re-renders
    };
  }, []);

  const handleStartGame = async () => {
    const response = await StartGame();
    PingGameState();
  }
  useEffect(() => {
    if (auth && gameState) {
      setIsGameMaster(gameState.gameMaster === parseInt(auth.user!.PleyerId));
      gameState.players && setHasJoined(gameState.players.some(x => x.id === parseInt(auth.user!.PleyerId)));
      gameState.players && gameState.players.some(x => x.id === parseInt(auth.user!.PleyerId)) && dispatch(resetLoading());
    }
  }, [gameState]);

  const handleConfirmRole = async () => {
    dispatch(setLoading());
    const confirm = await ConfirmRole(parseInt(auth.user!.PleyerId));
    dispatch(resetLoading());
    if (confirm.data === "Role confirmed") {
      setRoleConfirmed(true);
    }
  };

  const handleCheckConfirm = async () => {
    dispatch(setLoading());
    const confirm = await CheckConfirm(parseInt(auth.user!.PleyerId));
    dispatch(resetLoading());
    if (confirm.data === "Sheriff confirmed") {
      setCheckConfirmed(true);
    }
  };

  const handleGameEnd = async () => {
    const end = await EndGame();
    PingGameState();
  };

  useEffect(() => {
    console.log("CHECK", check);
  }, [check]);
  useEffect(() => {
    if (gamePhase === "") {
      setIsLoading(true);
      dispatch(setLoading());
    } else {
      setIsLoading(false);
      dispatch(resetLoading());
    }
    if (gamePhase === "ShowRoles") {
      handleGetRole();
    }
    if (gamePhase === "SheriffConfirm") {
      handleCheckResult();
    }
  }, [gamePhase]);

  return (
    <div className="content-box maffia">
      <Button type="primary" htmlType="submit" onClick={handleGameEnd}>
        Avsluta
      </Button>
      <h1>Maffia</h1>
      <div className="maffia-game">
        {
          gamePhase && (gamePhase === "" || gamePhase === "Idle") ?
            <Form
              name="create-game-form"
              id="create-game-form"
              style={{ maxWidth: 600 }}
              initialValues={{ remember: true }}
              onFinish={handleCreateGame}
              autoComplete="create-game-form"
            // ref={formRef}
            >
              <h2>Namn</h2>
              <Form.Item<ICreateGameValues>
                label=""
                name="playerName"
                rules={[{ required: true, message: "Ange spelarnamn" }]}
              >
                <Input
                  name="create-game-form-player-name"
                  id="create-game-form-player-name"
                  autoComplete="create-game-form-player-name"
                />
              </Form.Item>
              <Form.Item wrapperCol={{ offset: 8, span: 16 }} className="submit-container">
                <Button type="primary" htmlType="submit">
                  Skapa spel
                </Button>
              </Form.Item>
            </Form>
            :
            gamePhase === "Setup" && !hasJoined ?
              <>
                <Form
                  name="join-game-form"
                  id="join-game-form"
                  style={{ maxWidth: 600 }}
                  initialValues={{ remember: true }}
                  onFinish={handleJoinGame}
                  autoComplete="create-game-form"
                // ref={formRef}
                >
                  <h2>Namn</h2>
                  <Form.Item<IJoinGameValues>
                    label=""
                    name="playerName"
                    rules={[{ required: true, message: "Ange spelarnamn" }]}
                  >
                    <Input
                      name="join-game-form-player-name"
                      id="join-game-form-player-name"
                      autoComplete="join-game-form-player-name"
                    />
                  </Form.Item>
                  <Form.Item wrapperCol={{ offset: 8, span: 16 }} className="submit-container">
                    <Button type="primary" htmlType="submit">
                      Gå med
                    </Button>
                  </Form.Item>
                </Form>
              </> 
              :
              gamePhase === "Setup" && !isLoading &&
              <>
                <div className="player-list">
                  {
                    gameState?.players.map((x: any, index) =>
                      <div className="player-row">
                        <div className="player-index">
                          {index + 1}.
                        </div>
                        <div className="player-name">
                          <p>{x.name}</p>
                        </div>
                        {
                          x.id === gameState.gameMaster &&
                          <div className="player-master">
                            <CrownFilled />
                          </div>
                        }
                      </div>
                    )
                  }
                </div>
                {
                  isGameMaster &&
                  <Button type="primary" htmlType="submit" onClick={handleStartGame} disabled={gameState && gameState.players && gameState.players.length < 3}>
                    Starta spelet
                  </Button>
                }
                {

                }
              </>
        }
        {
          gamePhase === "ShowRoles" && !isLoading && role ?
            <>
              {
                gameState && gameState.players ?
                  !roleConfirmed ?
                    <div className="role-show">
                      <p className="role-header">Din roll är</p>
                      <p className="role">{role}</p>
                      <Button type="primary" htmlType="submit" onClick={handleConfirmRole} disabled={false}>
                        Bekräfta
                      </Button>
                    </div>
                    :
                    <p>Väntar på andra spelares bekräftelser...</p>
                  :
                  <></>
              }
            </>
            :
            <>

            </>
        }
        {

          gamePhase === "SheriffConfirm" && role === "Sheriff" ?
            <>
              {
                gameState && gameState.players ?
                  !checkConfirmed ?
                    <div className="role-show sheriff-show">
                      {
                        <h2>{check.target.name} {check.isMafia ? <p>ÄR</p> : <p>är INTE</p>} maffia.</h2>
                      }
                      <Button type="primary" htmlType="submit" onClick={handleCheckConfirm} disabled={false}>
                        Bekräfta
                      </Button>
                    </div>
                    :
                    <p>Väntar på att sheriffernas bekräftelse...</p>
                  :
                  <>
                  </>
              }
            </>
            :
            <>

            </>
        }
        {
          (
            gamePhase === "Maffia" ||
            gamePhase === "Doctor" ||
            gamePhase === "Sheriff" ||
            gamePhase === "Wakeup" ||
            gamePhase === "Night" ||
            gamePhase === "PreDoctor" ||
            gamePhase === "PreSheriff" 
          ) && <MaffiaGame game={gameState!} gamePhase={gamePhase} playerId={parseInt(auth.user!.PleyerId)} role={role!} />
        }
      </div>
    </div>
  )
};
