import axiosInstance from "../api/axios";
import { PackingListItems } from "../store/types"

export const GetPackingList = async () => {
  try {
    const response = await axiosInstance.get("/api/PackingList/GetPackingList");

    if (!response.data) {
      throw new Error('Failed to fetch packing list');
    }
    return response.data.data as PackingListItems[];
  } catch (ex) {
    console.error(ex);
    return undefined;
  }
}

export const AddPackingListItem = async () => {

}

export const CheckCommonItem = async (values: any) => {
  try {
    const response = axiosInstance.post('/api/PackingList/CheckCommonItem', values);

    if (!response) {
      throw new Error('Failed to fetch missions');
    }
    return response;
  } catch (ex) {
    console.error(ex);
    return undefined;
  }
}

export const CheckItem = async (values: any) => {
  try {
    const response = axiosInstance.post('/api/PackingList/CheckItem', values);

    if (!response) {
      throw new Error('Failed to fetch missions');
    }
    return response;
  } catch (ex) {
    console.error(ex);
    return undefined;
  }
}