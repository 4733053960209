import React from "react";
import { login } from "./authApi";
import { useAppDispatch } from "../../store/hooks";
import { updateToken, resetLoading, setLoading } from "./authSlice";
import { useNavigate } from "react-router";
import { App, Button, Form, FormInstance, Input } from "antd";

type ILoginValues = {
  userName?: string;
  password?: string;
};

export const Login = () => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const { message } = App.useApp();
  const formRef = React.useRef<FormInstance>(null);

  const onSubmit = async (values: ILoginValues) => {
    dispatch(setLoading());
    const data = await login(values.userName as string, values.password as string);
    dispatch(resetLoading());
    if (data?.isSucceed && data?.data) {
      message.success("Login is successful.");
      dispatch(updateToken(data.data));
      navigate("/");
    } else if(data!=null) {
      data?.messages?.email &&
        formRef.current?.setFields([
          { name: "userName", errors: data.messages?.email },
        ]);
      data?.messages?.password &&
        formRef.current?.setFields([
          { name: "password", errors: data.messages?.password },
        ]);
    }else{
      message.error("Unexpected error occurred please try again later.");
    }
  };
  return (
    <div className="login-form">
      <Form
        name="unified-app-login"
        id="unified-app-login"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        initialValues={{ remember: true }}
        onFinish={onSubmit}
        autoComplete="unified-app-login"
        ref={formRef}
      >
        <Form.Item<ILoginValues>
          label="Användarnamn"
          name="userName"
          rules={[{ required: true, message: "Ange användarnamn!" }]}
        >
          <Input
            name="unified-app-login-name"
            id="unified-app-login-name"
            autoComplete="unified-app-login-name"
          />
        </Form.Item>

        <Form.Item<ILoginValues>
          label="Lösenord"
          name="password"
          rules={[{ required: true, message: "Ange lösenord!" }]}
        >
          <Input.Password
            name="unified-app-login-password"
            id="unified-app-login-password"
            autoComplete="unified-app-login-password"
          />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }} className="submit-container">
          <Button type="primary" htmlType="submit">
            Logga in
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};