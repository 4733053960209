import axiosInstance from "../api/axios";
import { ICorrectTapGameValues } from "../components/adminpages/CorrectMission";
import { iAppResponse } from "../store/appResponse";
import { ITapGame, ITapGameHighScores } from "../store/types";

export const GetTapGame = async (player: number) => {
  try {
    const response = await axiosInstance.get(`api/TapGame/GetTapGame/${player}`);
  
    if (!response.data) {
      throw new Error('Failed to fetch game');
    }
    return response.data.data;
  } catch (ex) {
    console.error(ex);
    return "Error";
  }
};

export const TapGameSucceded = async (game: ITapGame, score: number) => {
  try {
    const response = await axiosInstance.post("api/TapGame/TapGameSucceded", {game, score});
  
    if (!response.data) {
      throw new Error('Failed to hand in tap game');
    }
    return response.data.data;
  } catch (ex) {
    console.error(ex);
    return false;
  }
}

export const GetPendingTapGames = async () => {
  try {
    const response = await axiosInstance.get("api/TapGame/GetPendingTapGames");

    if (!response.data) {
      throw new Error('No pending games');
    }
    return response.data.data;
  } catch (ex) {
    console.error(ex);
    return undefined;
  }
}
export const CorrectTapGame = async (values: ICorrectTapGameValues[]) => {
  const formData = new FormData();

  values.forEach((form, index) => {
    formData.append(`CluesAndPoints[${index}].id`, form.id.toString());
    formData.append(`CluesAndPoints[${index}].playerId`, form.playerId.toString());
    formData.append(`CluesAndPoints[${index}].points`, form.points);
    formData.append(`CluesAndPoints[${index}].bonusPoints`, form.bonusPoints);

    // Append the file separately
    if (form.file) {
      formData.append(`CluesAndPoints[${index}].file`, form.file as unknown as File);
    }
  });

  try {
    const response = await axiosInstance.post<iAppResponse<string>>(
      '/api/TapGame/CorrectTapGame',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return response.data;
  } catch (ex) {
    console.error(ex);
    return undefined;
  }
}

export const GetTapGameHighScores = async () => {
  try {
    const response = await axiosInstance.get("api/TapGame/GetTapGameHighScores");

    if (!response) {
      throw new Error('No pending games');
    }
    return response.data;
  } catch (ex) {
    console.error(ex);
    return undefined;
  }
}