import { useEffect, useState } from "react";
import { FAQ } from "../../store/types";
import { CaretRightOutlined } from '@ant-design/icons';
import { Button, Collapse, CollapseProps, Form, FormInstance, Input, message } from "antd";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import React from "react";
import { resetLoading, selectAuth, setLoading } from "../../features/user/authSlice";
import { AnswerQuestion, CreateQuestion, GetQuestions } from "../../actions/FaqActions";

interface CreateQuestionValues {
  question: string;
}
export interface AnswerValues {
  id: number;
  answer: string;
}
export const Faq = ({ role }: { role: string[] }) => {
  const auth = useAppSelector(selectAuth);
  const [isAdmin, setIsAdmin] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [faqs, setFaqs] = useState<FAQ[]>([]);
  const [questions, setQuestions] = useState<CollapseProps["items"]>();
  const [unAnsweredQuestions, setUnAnsweredQuestions] = useState<CollapseProps["items"]>();
  const dispatch = useAppDispatch();
  const formRef = React.useRef<FormInstance>(null);
  const answerFormRef = React.useRef<FormInstance>(null);

  useEffect(() => {
    setIsAdmin(role.includes("HasRoleAdd"));
  }, [role]);
  const showSuccess = (message: string) => {
    messageApi.open({
      type: 'success',
      content: message,
      duration: 3,
    });
  };
  const GetFaqs = async () => {
    dispatch(setLoading())
    const questions = await GetQuestions();
    if (questions) {
      setFaqs(questions as unknown as FAQ[]);
      dispatch(resetLoading());
    }
  }
  const handleAnswerQuestion = async (values: AnswerValues) => {
    dispatch(setLoading());
    const answeredQuestion = await AnswerQuestion(values);
    if (answeredQuestion) {
      dispatch(resetLoading());
      answerFormRef.current?.resetFields();
      showSuccess("Fråga besvarad");
      GetFaqs();
    }
  };
  const setCollapseItems = (items: FAQ[]) => {
    const collapseItems: CollapseProps["items"] = [];
    const unAnsweredItems: CollapseProps["items"] = [];

    faqs && faqs.map(x => {
      x.answered ?
        collapseItems.push({
          key: x.id,
          label: <div className="faq-label"><p>{x.question}</p><p>/ {x.playerName}</p></div>,
          children: <div className="collapse-item-child"><p>{x.answer}</p></div>
        })
        :
        unAnsweredItems.push({
          key: x.id,
          label: <div className="faq-label"><p>(Obesvarad) {x.question}</p><p>/ {x.playerName}</p></div>,
          children: <div className="collapse-item-child">
            <Form
              name="answer-form"
              id="answer-form"
              style={{ maxWidth: 500 }}
              initialValues={{ remember: true }}
              onFinish={handleAnswerQuestion}
              autoComplete="answer-form"
              ref={answerFormRef}
            >
              <Form.Item<AnswerValues>
                label="Svara på frågan"
                name="answer"
                className="ask-form-input"
              >
                <Input.TextArea
                  name="ask-form-question"
                  id="ask-form-question"
                  autoComplete="ask-form-question"
                />
              </Form.Item>
              <Form.Item<AnswerValues> name="id" initialValue={x.id} />
              <Form.Item className="submit-container">
                <Button type="primary" htmlType="submit">
                  Svara
                </Button>
              </Form.Item>
            </Form>
          </div>
        })
    });
    setQuestions(collapseItems);
    setUnAnsweredQuestions(unAnsweredItems);
  }
  useEffect(() => {
    GetFaqs();
  }, []);

  useEffect(() => {
    faqs.length > 0 && setCollapseItems(faqs);
  }, [faqs]);

  const handleSubmit = async (values: CreateQuestionValues) => {
    const question: FAQ = {
      question: values.question,
      playerName: auth.user!.UserName,
      answer: "",
      answered: false
    }
    dispatch(setLoading());
    const createdQuestion = await CreateQuestion(question);
    if (createdQuestion) {
      dispatch(resetLoading());
      formRef.current?.resetFields();
      showSuccess("Fråga skickad");
    }
  };
  return (
    <div className="content-box">
      {contextHolder}
      <h1>Frågor & svar</h1>
      <p>Nedan ser du svar på frågor som ställts till arrangörerna. Längst ner på sidan kan du skicka din egen fråga.</p>
      <div className="faq-collapse-content">
        {
          questions && questions.length > 0 ?
            <Collapse items={questions} expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />} expandIconPosition="end" />
            : <></>
        }
        {
          isAdmin && unAnsweredQuestions && unAnsweredQuestions.length > 0 ?
            <Collapse items={unAnsweredQuestions} expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />} expandIconPosition="end" />
            : <></>
        }
      </div>
      {
        !isAdmin ? (
          <div className="ask-form">
            <Form
              name="ask-form"
              id="ask-form"
              style={{ maxWidth: 500 }}
              initialValues={{ remember: true }}
              onFinish={handleSubmit}
              autoComplete="ask-form"
              ref={formRef}
            >
              <Form.Item<CreateQuestionValues>
                label="Ställ din fråga"
                name="question"
                rules={[{ required: true, message: "Fyll i din fråga" }]}
              >
                <Input
                  maxLength={100}
                  name="ask-form-question"
                  id="ask-form-question"
                  autoComplete="ask-form-question"
                />
              </Form.Item>
              <Form.Item className="submit-container">
                <Button type="primary" htmlType="submit">
                  Skicka
                </Button>
              </Form.Item>
            </Form>
          </div>
        ) : (
          <></>
        )
      }

    </div>
  )
};