import axiosInstance from "../api/axios";
import { Point } from "../store/types";

export const GetPoints = async () => {
  try {
    const response = await axiosInstance.get<Point[]>('api/Points/GetPoints');
    if (!response.data) {
      throw new Error('Failed to fetch notifications');
    }

    return response.data as Point[];
  } catch (ex) {
    console.log(ex);
  }
}