import { App, Layout } from "antd";
import { Content, Header } from "antd/es/layout/layout";
import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router";
import { selectAuth } from "../../features/user/authSlice";
import { useAppSelector } from "../../store/hooks";
import BackGroundImage from "../BackgroundImage";

export const DefaultLayout = () => {
  const auth = useAppSelector(selectAuth);
  const navigate = useNavigate();
  let location = useLocation();
  const [current, setCurrent] = useState(
    location.pathname === "/" || location.pathname === ""
      ? "/"
      : location.pathname
  );

  useEffect(() => {
    if (location) {
      if (current !== location.pathname) {
        setCurrent(location.pathname);
      }
    }
  }, [location]);

  const handleClick = (key: string) => {
    navigate(key);
  };
  return (
    <App>
      <Layout className="layout">
        <Header style={{ display: "flex", alignItems: "center" }} className="header">
          {auth.user && <h3 className="header-logo">Uppesittarresan.</h3>}
          <p onClick={(e) => {handleClick("/login")}}>Logga in</p>
        </Header>
        <Content style={{ padding: "0 50px", minHeight: "400px" }}>
          <div className="content">
            <Outlet />
          </div>
        </Content>
      <BackGroundImage />
      </Layout>
    </App>
  );
};