import axiosInstance from "../api/axios";
import { ICorrectedMissionValues, IPlayerCluesAndPoints } from "../components/templates/CorrectMissionTemplate";
import { iAppResponse } from "../store/appResponse";
import { IAssignMission, Mission, MissionHandIn } from "../store/types";

export const AddMission = async (values: Mission, file: File | undefined): Promise<iAppResponse<boolean> | undefined> => {
  const formData = new FormData();
  for (const key in values) {
    if (Object.prototype.hasOwnProperty.call(values, key)) {
      formData.append(key, values[key as keyof Mission] as any);
    }
  }

  if (file) {
    formData.append('upload', file);
  }

  try {
    const response = await axiosInstance.post<iAppResponse<boolean>>(
      '/api/mission/AddMission',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return response.data;
  } catch (ex) {
    console.error(ex);
    return undefined;
  }
};

export const AssignMission = async (mission: IAssignMission) => {
  try {
    const response = await axiosInstance.post<iAppResponse<string>>(
      'api/mission/AssignMission',
      mission
    )
    if (!response.data) {
      throw new Error('Failed to assign mission');
    }
    return response.data.data;
  } catch (ex) {
    console.error(ex);
    return "Error";
  }
}
export const GetMissions = async () => {
  const response = await axiosInstance("api/mission/GetMissions");
  if (!response.data) {
    throw new Error('Failed to fetch missions');
  }
  return response.data;
}

export const GetMissionTemplates = async () => {
  const response = await axiosInstance.get("api/mission/GetMissionTemplates");
  if (!response.data) {
    throw new Error('Failed to fetch missions');
  }
  return response.data;
}
export const GetMissionsByUser = async (userId: number) => {
  const response = await axiosInstance.get(`/api/mission/GetMissionsByUser/${userId}`);
  if (!response.data) {
    throw new Error('Failed to fetch missions');
  }
  return response.data.data;
}

export const GetPendingMissions = async () => {
  const response = await axiosInstance.get("/api/mission/GetPendingMissions");

  if (!response.data) {
    throw new Error('Failed to fetch missions');
  }
  return response.data.data as Mission[];
}

export const HandInMission = async (mission: MissionHandIn): Promise<iAppResponse<string> | undefined> => {
  const formData = new FormData();

  for (const key in mission) {
    if (Object.prototype.hasOwnProperty.call(mission, key)) {
      formData.append(key, mission[key as keyof MissionHandIn] as any);
    }
  }

  try {
    const response = await axiosInstance.post<iAppResponse<string>>(
      '/api/mission/HandInMission',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    return response.data;
  } catch (ex) {
    console.error(ex);
    return undefined;
  }

}

export const CorrectMission = async (values: ICorrectedMissionValues): Promise<iAppResponse<string> | undefined> => {
  const formData = new FormData();

  formData.append('missionId', values.missionId.toString());

  values.cluesAndPoints.forEach((item: IPlayerCluesAndPoints, index: number) => {
    formData.append(`cluesAndPoints[${index}].bonusPoints`, item.bonusPoints);
    formData.append(`cluesAndPoints[${index}].points`, item.points);
    formData.append(`cluesAndPoints[${index}].playerId`, item.playerId.toString());
    formData.append(`cluesAndPoints[${index}].File`, item.clue[0]);
  });

  try {
    const response = await axiosInstance.post<iAppResponse<string>>(
      '/api/mission/CorrectMission',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      }
    );
    return response.data;
  } catch (ex) {
    console.error(ex);
    return undefined;
  }
};
