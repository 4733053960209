import React, { useEffect, useState } from "react";
import { List, Typography, Button } from "antd";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { produceWithPatches } from "immer";
import { Player } from "../../store/types";

export const DraggableList = ({ droppableId, playerList, onOrderChanged }: { droppableId: number, playerList: Player[], onOrderChanged: (playerOrder: number[]) => void }) => {
  // Initialize the state with some data
  const [items, setItems] = useState<Player[]>([]);

  useEffect(() => {
    setItems(playerList);
  }, []);
  // Handle the drag end event
  const handleOnDragEnd = (result: any) => {
    if (!result.destination) return;
    const playerIds: number[] = [];
    const reorderedItems = Array.from(items);
    const [reorderedItem] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, reorderedItem);

    reorderedItems.map(x => playerIds.push(x.id!));
    setItems(reorderedItems);
    onOrderChanged(playerIds);
  };

  useEffect(() => {
    const order: number[] = [];
    items.map(x => order.push(x.id!));
    onOrderChanged(order);
  }, [items]);
  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="droppable-list">
        {(provided) => (
          <ul
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {items.map((x, i) =>
                <Draggable draggableId={x.id!.toString()} index={i} key={x.id} >
                  {(provided) => (
                    <li key={x.id} ref={provided.innerRef} {...provided.dragHandleProps} {...provided.draggableProps}>
                      <div className="player-name">{(i+1) + ". " + x.username}</div>
                    </li>
                  )}
                </Draggable>
              )}
            {provided.placeholder}
          </ul>
        )}
      </Droppable>
    </DragDropContext>
  );
};

