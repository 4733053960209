import { Button, message, Select, Space } from "antd";
import { useState } from "react";
import { IMissionTemplate } from "../../store/types";
import { CreateMissionTemplate } from "../templates/CreateMissionTemplate";

export const CreateMissionTemplatePage = () => {
  const [templateItem, setTemplateItem] = useState<IMissionTemplate>();
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [messageApi, contextHolder] = message.useMessage();

  const showSuccess = () => {
    messageApi.open({
      type: 'success',
      content: 'Mall sparad',
      duration: 3,
    });
  };

  const handleTemplateSave = (success: boolean) => {
    if (success) {
      showSuccess();
    }
    setTemplateItem(undefined);
    setIsCreating(false);
  }
  const handleCreateNewTemplate = () => {
    const template: IMissionTemplate = {
      title: "",
      description: "",
      assignedTo: <Select />
    }
    setTemplateItem(template);
    setIsCreating(true);
  };

  return (
    <div className="content-box">
      {contextHolder}
      <h1>Skapa uppdragsmall</h1>
      <p>Här skapar du uppdragsmall och ställer in de viktigaste valen. När du sparat listas uppdragen under "Tilldela upprag". Där bestämmer du vem/vilka som ska få uppdraget.</p>
      <Space style={{ width: '100%' }} direction="vertical">
        <div className="create-mission-template-button">
          <Button type="primary" onClick={handleCreateNewTemplate}>Ny</Button>
        </div>
        {
          isCreating && templateItem && <CreateMissionTemplate template={templateItem} onSave={(success: boolean) => handleTemplateSave(success)} />
        }
      </Space>


    </div>
  )
};