import Background from "../assets/default-background.png";
import { selectAuth } from "../features/user/authSlice";
import { useAppSelector } from "../store/hooks";

const BackGroundImage = () => {
    const auth = useAppSelector(selectAuth);
    return (
        <img src={Background} alt="house" className={`house-image ${auth.user ? "fade-out" : ""}`} />
    )
};

export default BackGroundImage;