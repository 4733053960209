import { useEffect, useState } from "react";
import { GetCluesByUser } from "../../actions/CluesActions";
import { resetLoading, selectAuth, setLoading } from "../../features/user/authSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { Clue } from "../../store/types";

const UserCluesPage = () => {
  const auth = useAppSelector(selectAuth);
  const dispatch = useAppDispatch();
  const [clues, setClues] = useState<Clue[]>();
  const fetchClues = async () => {
    dispatch(setLoading());
    try {
      const data = await GetCluesByUser(auth.user!.PleyerId as unknown as number);
      setClues(data);
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(resetLoading());
    }
  }

  useEffect(() => {
    fetchClues();
  }, []);

  return (
    <div className="content-box">
      <h1>Mina ledtrådar</h1>
      {
        clues && clues.map(x => {
          return <img src={x.imageUrl} />
        })
      }
    </div>
  )
};

export default UserCluesPage;