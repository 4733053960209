import axiosInstance from "../../api/axios";
import { iAppResponse } from "../../store/appResponse";

export const login = async (userName: string, password: string) => {
  const response = await axiosInstance.post<
  iAppResponse<{ accessToken: string; refreshToken: string }>
  >('/user/login', {
    userName: userName,
    password: password,
  }).catch((ex: string)=>{
    console.log(ex);
  });
  return response?.data;
};
export const refreshToken = async (data: {
  accessToken: string;
  refreshToken: string;
}) => {
  const response = await axiosInstance.post<
  iAppResponse<{ accessToken: string; refreshToken: string }>
  >('/user/refreshToken', data).catch((ex)=>{
    console.log(ex);
  });;
  return response?.data;
};
export const register = async (userName: string, email: string, password: string, playerId: number) => {
  const response = await axiosInstance.post<iAppResponse<{}>>(
    '/user/register',
    {
      userName: userName,
      email: email,
      password: password,
      playerId: playerId
    }
  ).catch((ex)=>{
    console.log(ex);
  });
  return response?.data;
};
export const logout = async () => {
  const response = await axiosInstance.post<iAppResponse<boolean>>(
    '/user/logout'
  ).catch((ex)=>{
    console.log(ex);
  });
  return response?.data;
};
export const profileApi = async () => {
  const response = await axiosInstance.post('/user/profile').catch((ex)=>{
    console.log(ex);
  });
  return response?.data;
};