import { useEffect, useState } from "react";
import { Button, Form, FormInstance, Input, message } from "antd"
import { Guess } from "../../store/types";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { resetLoading, selectAuth, setLoading } from "../../features/user/authSlice";
import React from "react";
import { GetGuessByUser, SendGuess } from "../../actions/GuessActions";
import { genInputSmallStyle } from "antd/es/input/style";

const GetGuesses: Guess = {
  id: 0,
  playerId: 1,
  currentGuess: "",
  guessesLeft: 3
}

interface GuessRequest {
  guess: string;
}
const GuessDestinationPage = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const dispatch = useAppDispatch();
  const auth = useAppSelector(selectAuth);
  const [guesses, setGuesses] = useState<Guess>();
  const [inputValue, setInputValue] = useState("");
  const formRef = React.useRef<FormInstance>(null);

  const showSuccess = () => {
    messageApi.open({
      type: 'success',
      content: 'Din gissning är inskickad',
      duration: 3,
    });
  };

  const Guess = async () => {
    dispatch(setLoading());
    const guess = await GetGuessByUser(parseInt(auth.user!.PleyerId));
    if (guess) {
      setGuesses(guess);

    }
    dispatch(resetLoading());
  };

  useEffect(() => {
    Guess();
  }, []);

  const handleInputChange = (value: string) => {
    setInputValue(value);
  }
  const handleSubmit = async (values: GuessRequest) => {
    setInputValue("");
    const request: Guess = {
      id: guesses!.id,
      guessesLeft: guesses!.guessesLeft - 1,
      playerId: guesses!.playerId,
      currentGuess: values.guess
    }
    dispatch(setLoading());
    const response = await SendGuess(request);
    if (response) {
      formRef.current?.resetFields();
      await Guess();
      showSuccess();
    }
    dispatch(resetLoading());
  };

  return (
    <div className="content-box">
      {contextHolder}
      <h1>Gissa resmål</h1>
      <p>Lämna in din gissning på resmålet. Du kan gissa 3 gånger innan avresedagen.</p>

      <div className="guess-form">
        {
          guesses &&
          <>
            <h2>Gissning</h2>
            <Form
              name="guess-form"
              id="guess-form"
              style={{ maxWidth: 600 }}
              initialValues={{ remember: true }}
              onFinish={handleSubmit}
              autoComplete="guess-form"
              ref={formRef}
            // onValuesChange={updateRequest}
            >
              <Form.Item
                label=""
                name="guess"
                rules={[{ required: true, message: "Ange gissning" }]}
              >
                <Input
                  name="guess-form-guess"
                  id="guess-form-guess"
                  autoComplete="guess-form-guess"
                  onChange={e => handleInputChange(e.target.value)}
                  disabled={guesses && guesses.guessesLeft === 0}
                />
              </Form.Item>

              <Form.Item wrapperCol={{ offset: 8, span: 16 }} className="submit-container">
                <Button type="primary" htmlType="submit" disabled={inputValue === ""}>
                  Skicka in
                </Button>
              </Form.Item>

            </Form>
            <div className="guess-info">
              <p><span>Din gissning: </span>
                {
                  guesses && guesses.currentGuess ?
                    guesses.currentGuess : ""}</p>

              {
                <p><span>Gissningar kvar: </span> {guesses ? guesses.guessesLeft : 3}</p>
              }
            </div>
          </>
        }
      </div>
    </div>
  )
};

export default GuessDestinationPage;