import { iUser } from "../../features/user/authSlice";
import { NotificationTemplate, Player }from "../../store/types";

export const getNotificationTemplate = (type: string, assignedTo: number[], player: iUser, allPlayers: Player[], level?: number, tapPlayer?: Player) => {
  const GetAllPlayerIds = (players: Player[]) => {
    const ids = players.map(x => x.id!);
    return ids;
  }

  const GetAssignedUserNames = (players: number[]) => {
    const userNames = allPlayers.filter(x => players.includes(x.id!)).map(x => x.username);
    return userNames;
  }
  
  const GetNextPlayer = (playerId: number) => {
    const playerIndex = assignedTo.indexOf(playerId);
    const nextPlayer = assignedTo[playerIndex + 1];

    return [nextPlayer];
  }
  const notificationTemplates: NotificationTemplate[] = [
    {
      type: "correctMission",
      notification: {
        assignedTo: assignedTo,
        message: "Ett uppdrag har rättats! Under 'Poängställning' kan du se dina poäng.",
        isRead: false
      }
    },
    {
      type: "assignSingleMission",
      notification: {
        assignedTo: assignedTo,
        message: "Du har fått ett uppdrag! Gå in på 'Mina uppdrag' och sätt igång.",
        isRead: false
      }
    },
    {
      type: "assignCommonMission",
      notification: {
        assignedTo: assignedTo,
        message: "Du har fått ett gemensamt uppdrag! Gå in på 'Mina uppdrag' och sätt igång.",
        isRead: false
      }
    },
    {
      type: "assignAllMission",
      notification: {
        assignedTo: assignedTo,
        message: "Du har fått ett gemensamt uppdrag tillsammans med alla andra! Gå in på 'Mina uppdrag' och sätt igång.",
        isRead: false
      }
    },
    {
      type: "singleMissionPending",
      notification: {
        assignedTo: GetAllPlayerIds(allPlayers).filter(x => x !== parseInt(player.PleyerId)),
        message: `${player.UserName} har slutfört ett uppdrag!`,
        isRead: false
      }
    },
    {
      type: "commonMissionPending",
      notification: {
        assignedTo: GetAllPlayerIds(allPlayers).filter(x => !assignedTo.includes(x)),
        message: `${GetAssignedUserNames(assignedTo).map((x, index) => {
          if (index + 1 !== assignedTo.length) {
            return ((index > 0 ? " ":"") + x);
          }
          return " och " + x + " ";
        })}har slutfört ett gemensamt uppdrag!`,
        isRead: false
      }
    },
    {
      type: "commonMissionAnswerSent",
      notification: {
        assignedTo: assignedTo.filter(x => x !== parseInt(player.PleyerId)),
        message: `${player.UserName} har svarat på erat gemensamma uppdrag!`,
        isRead: false
      }
    },
    {
      type: "allMissionPending",
      notification: {
        assignedTo: assignedTo,
        message: "Ditt gemensamma uppdrag har skickats för rättning!",
        isRead: false
      }
    },
    {
      type: "allMissionNextPlayer",
      notification: {
        assignedTo: GetNextPlayer(parseInt(player.PleyerId)),
        message: `${player.UserName} har svarat på ert gemensamma uppdrag! Det är din tur att svara!`,
        isRead: false
      }
    },
    {
      type: "allMissionAnswerSent",
      notification: {
        assignedTo: assignedTo.filter(x => x !== parseInt(player.PleyerId)),
        message: `${player.UserName} har svarat på erat gemensamma uppdrag!`,
        isRead: false
      }
    },
    {
      type: "allMissionComplete",
      notification: {
        assignedTo: assignedTo,
        message: "Ditt gemensamma uppdrag där alla deltar är rättat! Du har blivit tilldelad poäng och ledtråd!",
        isRead: false
      }
    },
    {
      type: "commonMissionComplete",
      notification: {
        assignedTo: assignedTo,
        message: "Ditt gemensamma uppdrag har rättats! Du har blivit tilldelad poäng och ledtråd!",
        isRead: false
      }
    },
    {
      type: "secretMissionCorrect",
      notification: {
        assignedTo: GetAllPlayerIds(allPlayers).filter(x => x !== tapPlayer?.id),
        message: `${tapPlayer?.username} har slutfört ett hemligt uppdrag!`,
        isRead: false
      }
    },
    {
      type: "tapGameCorrect",
      notification: {
        assignedTo: (tapPlayer && level) ? GetAllPlayerIds(allPlayers).filter(x => x !== tapPlayer.id) : [],
        message: (tapPlayer && level) ? `${tapPlayer.username} har klarat nivå ${level} i klickspelet!` : "",
        isRead: false
      }
    }
  ]
  const template = notificationTemplates.find(x => x.type === type)?.notification;

  if (template) {
    return template;
  }
  return undefined;
}