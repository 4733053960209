import { useEffect } from "react";
import { GetPlayers } from "../actions/PlayerActions";
import { selectPlayers } from "../features/players/playersSlice";
import { selectAuth } from "../features/user/authSlice";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import Arlanda from "../assets/arlanda.png";

export const AdminHomePage = () => {
  const dispatch = useAppDispatch();
  const auth = useAppSelector(selectAuth);
  useEffect(() => {
    GetPlayers(dispatch);
  }, []);

  return (
    <div className="content-box">
      <h1>Hej, {auth.user?.UserName}!</h1>
      <h3>VIKTIG INFO!</h3>
      <div className="flight-info">
        <p>
          Snart är det dags! Här följer viktig information om den inledande delen av resan:
        </p>
        <p className="flight-info-text">
          Plats: Arlanda terminal 5 (se karta)
        </p>
        <p className="flight-info-text">
          Tid: 05:00
        </p>
        <p className="flight-info-text">
          Bagage: Handbagage, max 8kg + liten väska under sätet
        </p>
      </div>
      <div className="arlanda-image">
        <img src={Arlanda} />
      </div>
    </div>
  );
};