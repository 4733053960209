import axiosInstance from "../api/axios"
import { PlayerOrder } from "../store/types";
import { iAppResponse } from "../store/appResponse"

export const GetPlayerOrder = async (missionId: number) => {
  try {
    const response = await axiosInstance.get<iAppResponse<PlayerOrder>>(`/api/playerorder/GetMissionPlayerOrder/${missionId}`);
    if (!response.data) {
      throw new Error('No order found');
    }

    return response.data;
  } catch (ex) {
    return undefined;
  }

}
