import axiosInstance from "../api/axios"
import { Guess } from "../store/types"

export const GetGuessByUser = async (playerId: number) => {
  const response = await axiosInstance.get(`/api/Guess/GetGuessByUser/${playerId}`);

  if (!response.data) {
    throw new Error('Failed to fetch guess');
  }
  return response.data.data;
}

export const SendGuess = async (request: Guess) => {
    const response = await axiosInstance.post("/api/Guess/SendGuess", request);

    if (!response) {
        throw new Error('Failed to send guess');
    }

    return response;
}