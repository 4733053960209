import { useEffect, useState } from "react";
import { Badge, notification } from "antd";
import { BellFilled } from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { resetLoading, selectAuth, setLoading } from "../../features/user/authSlice";
import { GetNotifications, MarkNotificationAsRead } from "../../actions/NotificationActions";
import { Notification } from "../../store/types";

const Notifications = () => {
  const [api, contextHolder] = notification.useNotification();
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const auth = useAppSelector(selectAuth);
  const dispatch = useAppDispatch();

  const handleClose = async (key: number | undefined) => {
    dispatch(setLoading());
    const data = key && await MarkNotificationAsRead(key);
    if (data) {
      auth.user && fetchNotifications(parseInt(auth.user.PleyerId));
    } else {
      dispatch(resetLoading());
    }
  };

  const openNotification = () => {
    notifications.map(x => {
      const key = x.id;
      api.open({
        key: key,
        message: "",
        description: x.message,
        duration: 0,
        placement: "topRight",
        className: "notification-box",
        onClose: () => handleClose(key)
      })
    })
  };

  const fetchNotifications = async (playerId: number) => {
    dispatch(setLoading());
    const data = await GetNotifications(playerId);
    if (data) {
      setNotifications(data)
      dispatch(resetLoading());
    } else {
      dispatch(resetLoading());
    }
  }

  useEffect(() => {
    auth.user && fetchNotifications(parseInt(auth.user.PleyerId));
  }, []);

  return (
    <>
      {contextHolder}
      <a onClick={openNotification}>
        <Badge
          count={notifications.length}
          size="small"
          showZero
          status={notifications.length > 0 ? "error" : "success"}
          color={notifications.length > 0 ? "#ff4d4f" : "#5c7365"}
        >
          <BellFilled className="notification-icon" />
        </Badge>
      </a>
    </>
  );
};

export default Notifications;
