import axiosInstance from "../api/axios"
import { Player } from "../store/types";
import { AppDispatch } from "../store/store";
import { setPlayers } from "../features/players/playersSlice";
import { setCurrentPlayer } from "../features/players/currentPlayerSlice";
import { iAppResponse } from "../store/appResponse";

export const GetPlayers = async (dispatch: AppDispatch) => {
    try {
        const response = await axiosInstance.get<iAppResponse<Player[]>>('api/players');
        if (response.data) {
            dispatch(setPlayers(response.data as unknown as Player[]));
        }
        return response.data;
    } catch (ex) {
        console.error(ex);
        return undefined;
    }
}

export const GetCurrentPlayer = async (dispatch: AppDispatch, userId: number) => {
    const response = await axiosInstance.get<Player>(`api/players/${userId}`);
    dispatch(setCurrentPlayer(response?.data));
}

export const CreatePlayer = async (player: Player) => {
    const response = await axiosInstance.post<Player>('api/players',
    {
      userName: player.username,
      firstName: player.firstName,
      lastName: player.lastName,
      assignedMissions: [],
      acomplishedMissions: []
    }
    ).catch((ex) => {
        console.log(ex);
    });
    return response?.data as Player;
}