import { Form, Button, Divider, Input, Upload, UploadFile, Checkbox } from "antd"
import { Mission, MissionComment, Player, PlayerOrder } from "../../store/types"
import { UploadOutlined } from '@ant-design/icons';
import { CorrectMission } from "../../actions/MissionsActions";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store/store";
import { resetLoading, selectAuth, setLoading } from "../../features/user/authSlice";
import { CreateNotification } from "../../actions/NotificationActions";
import { getNotificationTemplate } from "./NotificationTemplate";
import { useAppSelector } from "../../store/hooks";
import { selectPlayers } from "../../features/players/playersSlice";
import { GetPlayerOrder } from "../../actions/PlayerOrderActions";

export interface ICorrectedMissionValues {
  missionId: number;
  cluesAndPoints: IPlayerCluesAndPoints[];
}

export interface IPlayerCluesAndPoints {
  playerId: number;
  clue: File[];
  points: string;
  bonusPoints: string;
}

export const CorrectMissionTemplate = ({ mission, playersList, PendingMissions, showSuccess, comments }: { mission: Mission; playersList: Player[]; PendingMissions: () => void; showSuccess: () => void; comments?: MissionComment[] }) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [playerOrder, setPlayerOrder] = useState<PlayerOrder>();
  const [isSameClue, setIsSameClue] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();
  const auth = useAppSelector(selectAuth);
  const handleFileChange = ({ fileList }: { fileList: UploadFile[] }) => {
    setFileList(fileList);
  };
  const players = useAppSelector(selectPlayers);

  const fetchPlayerOrder = async () => {
    const data = await GetPlayerOrder(mission.id!);

    if (data) {
      setPlayerOrder(data.data);
    }
  }
  useEffect(() => {
    mission.playerOrder && fetchPlayerOrder();
  }, [mission]);
  const handleSubmit = async (values: any) => {
    const clueAndPointsArray: IPlayerCluesAndPoints[] = [];
    const assignedTo: number[] = [];
    let uniqueAssignedTo: number[];
    Object.keys(values).forEach(key => {
      const keyId = key.split("_", key.length)[1];
      assignedTo.push(parseInt(keyId));
    });
    uniqueAssignedTo = [...new Set(assignedTo)];

    uniqueAssignedTo.map(x => {
      clueAndPointsArray.push({
        playerId: x,
        clue: isSameClue ? [fileList[0]?.originFileObj] : values[`clue_${x}`],
        points: values[`points_${x}`],
        bonusPoints: values[`bonusPoints_${x}`]
      });
    });
    const request: ICorrectedMissionValues = {
      missionId: mission.id!,
      cluesAndPoints: clueAndPointsArray
    };

    dispatch(setLoading());
    const correctedMission = await CorrectMission(request);

    if (correctedMission) {
      const notification = getNotificationTemplate(correctedMission.data!, uniqueAssignedTo, auth.user!, players.players);
      notification && CreateNotification(notification);
      PendingMissions();
      showSuccess();
      setFileList([]);
      dispatch(resetLoading());
    } else {
      dispatch(resetLoading());
    }
  };

  const renderTextWithLinks = (text: string, hyperlinkText: string) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.split(' ').map((word, index) => {
      if (urlRegex.test(word)) {
        return (
          <>
            <br />
            <br />
            <a key={index} href={word.trim()} target="_blank" rel="noopener noreferrer">
              {hyperlinkText}
            </a>
            <br />
            <br />
          </>
        );
      }
      return <span key={index}>{word} </span>;
    });
  };

  return (
    <div className="collapse-item-child">
      <p>{mission.description !== "" && renderTextWithLinks(mission.description, "Spela här")}</p>
      <div className="collapse-item-image">
        {
          mission.files && mission.files.filter(y => !y.uploadedByUser).map(x => {
            return <img src={x.imageUrl} />
          })
        }
      </div>
      <div className="hand-in-form">
        <Divider style={{ borderColor: "#cfb193" }} dashed />
        <h4>Inskickat</h4>
        <Form
          name="correct-mission-form"
          id="correct-mission-form"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          initialValues={{ remember: true }}
          onFinish={handleSubmit}
          autoComplete="correct-mission-form"
        >
          {
            mission.assignedToType > 0 &&
            <Checkbox
              checked={isSameClue}
              onChange={() => setIsSameClue(!isSameClue)}
            >
              Alla får samma ledtråd
            </Checkbox>
          }
          {isSameClue &&
            <Form.Item
              label="Gemensam ledtråd till alla"
              getValueFromEvent={(e) => Array.isArray(e) ? e : e && [e.file]}
              rules={[{ required: true, message: "Ladda upp gemensam ledtråd" }]}
            >
              <Upload
                name="file"
                listType="picture"
                maxCount={1}
                beforeUpload={() => false}
                fileList={fileList}
                onChange={handleFileChange}
              >
                <Button type="default" icon={<UploadOutlined />}>Ladda upp ledtråd</Button>
              </Upload>
            </Form.Item>
          }
          <div className="sent-answers">
            {
              mission.assignedTo!.map(x => {
                const file = mission.files?.find(y => y.playerId === x);

                return (
                  <div>
                    <p>Inskickat av {players.players.find(y => y.id! === x)?.username}</p>
                    {
                      file ?
                        file.fileType.includes("audio") ?
                          <div className="video-wrapper">
                            <audio controls>
                              <source src={file.imageUrl} type={file.fileType} />
                              <p>Ljudet kan inte spelas upp, ladda ner det <a href={file.imageUrl}>här.</a></p>
                            </audio>
                          </div>
                          :
                          file.fileType.includes("video") ?
                            <div className="video-wrapper">
                              <video width="100%" controls>
                                <source src={file.imageUrl} type={file.fileType} />
                                <p>Videon kan inte spelas upp, ladda ner den <a href={file.imageUrl}>här.</a></p>
                              </video>
                            </div>
                            :
                            file.fileType.includes("image") ?
                              <img src={file.imageUrl} />
                              : <a href={file.imageUrl}>Ladda ner fil</a>
                        : <></>
                    }
                    {
                      comments && comments.filter(c => (c.missionId === mission.id! && c.playerId === x)).map(y => {
                        return (<>
                          <h3>Kommentar</h3>
                          <p>{y.comment}</p>
                        </>)
                      })
                    }
                    {!isSameClue &&
                      <Form.Item
                        label="Ledtråd"
                        name={`clue_${x}`}
                        rules={[{ required: true, message: "Ladda upp ledtråd" }]}
                        getValueFromEvent={(e) => Array.isArray(e) ? e : e && [e.file]}
                      >
                        <Upload
                          name={`clue_${x}`}
                          listType="picture"
                          maxCount={1}
                          beforeUpload={() => false}
                        >
                          <Button type="default" icon={<UploadOutlined />}>Ladda upp ledtråd</Button>
                        </Upload>
                      </Form.Item>
                    }
                    <Form.Item
                      label="Poäng"
                      name={`points_${x}`}
                      rules={[{ required: true, message: "Ange poäng" }]}
                    >
                      <Input
                        name="correct-mission-form-points"
                        id="correct-mission-form-points"
                        autoComplete="correct-mission-form-points"
                      />
                    </Form.Item>
                    <Form.Item
                      label="Bonuspoäng"
                      name={`bonusPoints_${x}`}
                      rules={[{ required: true, message: "Ange poäng" }]}
                    >
                      <Input
                        name="correct-mission-form-bonus-points"
                        id="correct-mission-form-bonus-points"
                        autoComplete="correct-mission-form-bonus-points"
                      />
                    </Form.Item>
                  </div>
                )
              })
            }
          </div>
          <Form.Item wrapperCol={{ offset: 8, span: 16 }} className="submit-container">
            <Button type="primary" htmlType="submit">
              Spara
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};
