import axiosInstance from "../api/axios"
import { iAppResponse } from "../store/appResponse"
import { CountDownTimer } from "../store/types"

export const GetCountdownTimer = async (missionId: number) => {
    try {
        const response = await axiosInstance.get<iAppResponse<CountDownTimer>>(`api/CountDownTimer/GetCountdownTimer/${missionId}`);

        if (!response.data) {
            throw new Error('No timers found');
        }

        return response.data;
    } catch (ex) {
        return undefined;
    }
}