import { Button, Divider, Upload, UploadFile } from "antd";
import { Mission, MissionComment, PlayerOrder } from "../../store/types";
import { UploadOutlined } from '@ant-design/icons';
import { useEffect, useState } from "react";
import { useAppSelector } from "../../store/hooks";
import { selectPlayers } from "../../features/players/playersSlice";
import { selectAuth } from "../../features/user/authSlice";
import CountdownTimer from "./CountdownTimer";
import { GetPlayerOrder } from "../../actions/PlayerOrderActions";
import { CaretRightOutlined } from '@ant-design/icons';
import TextArea from "antd/es/input/TextArea";

export const UserMissionTemplate = ({
  mission,
  onHandIn,
  comments
}: {
  mission: Mission;
  onHandIn?: (mission: Mission, fileList: UploadFile[], comment?: string) => void;
  comments?: MissionComment[];
}) => {
  const auth = useAppSelector(selectAuth);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const players = useAppSelector(selectPlayers);
  const [playerOrder, setPlayerOrder] = useState<PlayerOrder>();
  const handleFileChange = ({ fileList }: { fileList: UploadFile[] }) => {
    setFileList(fileList);
  };
  const [userComment, setUserComment] = useState<string>();

  const fetchPlayerOrder = async () => {
    const data = await GetPlayerOrder(mission.id!);

    if (data) {
      setPlayerOrder(data.data);
    }
  }
  useEffect(() => {
    mission.playerOrder && fetchPlayerOrder();
  }, [mission]);

  const handleCommentChange = (event: any) => {
    setUserComment(event.target.value);
  }
  const missionFullfilled = () => {
    if (mission.assignedToType === 0) {
      if (mission.userUpload && fileList.length > 0) {
        return false;
      } else if (!mission.userUpload) {
        return false;
      }
    }
    if (mission.assignedToType > 0 && mission.userUpload && !mission.files!.some(x => x.playerId === parseInt(auth.user!.PleyerId))) {
      if ((playerOrder && playerOrder.assignedTo[playerOrder.currentIndex] !== parseInt(auth.user!.PleyerId)) || fileList.length === 0) {
        return true;
      }
      return false;
    }
    return true;
  }
  const handleSubmit = () => {
    onHandIn && onHandIn(mission, fileList, userComment);
  };

  const renderTextWithLinks = (text: string, hyperlinkText: string) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.split(' ').map((word, index) => {
      if (urlRegex.test(word)) {
        return (
          <>
            <br />
            <br />
            <a key={index} href={word.trim()} target="_blank" rel="noopener noreferrer">
              {hyperlinkText}
            </a>
            <br />
            <br />
          </>
        );
      }
      return <span key={index}>{word} </span>;
    });
  };

  return (
    <div className="collapse-item-child">
      <p>{mission.description !== "" && renderTextWithLinks(mission.description, "Spela här")}</p>
      <div className="collapse-item-image">
        {
          mission.files && mission.files.filter(y => !y.uploadedByUser).map(x => {
            return <img src={x.imageUrl} />
          })
        }
      </div>
      <div className="hand-in-form">
        <Divider style={{ borderColor: "#cfb193" }} dashed />
        <h4>{(mission.pending || mission.accomplished) ? "Inskickat" : "Svara på uppdraget"}</h4>
        {
          mission.assignedToType !== 0 ?
            <div>
              <p>Det här uppdraget utförs {mission.assignedToType === 2 ? " av alla spelare" + (mission.playerOrder ? " i turordningen:" : "") : "av dessa spelare:"}</p>
              {
                mission.assignedToType === 1 ?
                  mission.assignedTo &&
                  mission.assignedTo.map(x => <p>{players.players.find(y => y.id === x)?.username}</p>)
                  : <></>
              }
              {
                mission.assignedToType === 2 && mission.playerOrder ?
                  <div className="player-order">
                    {
                      playerOrder && playerOrder.assignedTo.map((x, index) =>
                        <div className={playerOrder.currentIndex === index && (!mission.pending && !mission.accomplished) ? "player active" : "player inactive"}>
                          <CaretRightOutlined className="icon" />
                          <p>{players.players.find(y => y.id! === x)?.username}</p>
                        </div>
                      )
                    }
                  </div>
                  :
                  <></>
              }
            </div>
            : <></>
        }
        {
          mission.assignedToType === 2 && mission.userUpload ?
            mission.playerOrder && (!mission.pending && !mission.accomplished) ?
              <Upload
                name="file"
                listType="picture"
                maxCount={1}
                beforeUpload={() => false}
                fileList={fileList}
                onChange={handleFileChange}
                disabled={mission.playerOrder && playerOrder && playerOrder.currentIndex !== mission.assignedTo!.findIndex(x => x === parseInt(auth.user!.PleyerId))}
              >
                <Button type="default" icon={<UploadOutlined />}>Ladda upp fil</Button>
              </Upload>
              :
              (!mission.pending && !mission.accomplished) && 
              <Upload
                name="file"
                listType="picture"
                maxCount={1}
                beforeUpload={() => false}
                fileList={fileList}
                onChange={handleFileChange}
                disabled={mission.files!.some(x => x.playerId === parseInt(auth.user!.PleyerId))}
              >
                <Button type="default" icon={<UploadOutlined />}>Ladda upp fil</Button>
              </Upload>
            :
            mission.assignedToType == 1 && mission.userUpload && (!mission.pending && !mission.accomplished) && !mission.files!.some(x => x.playerId === parseInt(auth.user!.PleyerId)) ?
              <Upload
                name="file"
                listType="picture"
                maxCount={1}
                beforeUpload={() => false}
                fileList={fileList}
                onChange={handleFileChange}
                disabled={mission.files!.some(x => x.playerId === parseInt(auth.user!.PleyerId))}
              >
                <p>{mission.files!.some(x => x.playerId === parseInt(auth.user!.PleyerId))}</p>
                <Button type="default" icon={<UploadOutlined />}>Ladda upp fil</Button>
              </Upload>
              :
              mission.userUpload && (!mission.pending && !mission.accomplished) && !mission.files!.find(x => x.playerId === parseInt(auth.user!.PleyerId))?.playerId &&
              <Upload
                name="file"
                listType="picture"
                maxCount={1}
                beforeUpload={() => false}
                fileList={fileList}
                onChange={handleFileChange}
                disabled={mission.files!.some(x => x.playerId === parseInt(auth.user!.PleyerId))}
              >
                <Button type="default" icon={<UploadOutlined />}>Ladda upp fil</Button>
              </Upload>
        }
        {(!mission.pending && !mission.accomplished) && !mission.files!.find(x => x.playerId === parseInt(auth.user!.PleyerId))?.playerId &&
          <>
            <h3>Lämna kommentar</h3>
            <div className="user-comment-wrapper">
              <TextArea className="user-comment" onChange={handleCommentChange} >
              </TextArea>
            </div>
          </>
        }
        {(!mission.pending && !mission.accomplished) && <Button disabled={missionFullfilled()} type="default" onClick={handleSubmit} style={{ marginTop: "15px" }} >Skicka</Button>}
        {
          mission.hasTimeLimit && (!mission.pending && !mission.accomplished) &&
          <CountdownTimer mission={mission} />
        }
      </div>
      <div className="sent-answers">
        {
          mission.assignedTo!.map(x => {
            const file = mission.files!.find(y => y.playerId === x);

            return (
              file ?
                <div>
                  {mission.assignedToType > 0 && <p>Inskickat av {players.players.find(x => x.id! === file.playerId)?.username}</p>}
                  {
                    file.fileType.includes("audio") ?
                      <div className="video-wrapper">
                        <audio controls>
                          <source src={file.imageUrl} type={file.fileType} />
                          <p>Ljudet kan inte spelas upp, ladda ner det <a href={file.imageUrl}>här.</a></p>
                        </audio>
                      </div>
                      :
                      file.fileType.includes("video") ?
                        <div className="video-wrapper">
                          <video width="100%" controls>
                            <source src={file.imageUrl} type={file.fileType} />
                            <p>Videon kan inte spelas upp, ladda ner den <a href={file.imageUrl}>här.</a></p>
                          </video>
                        </div>
                        :
                        file.fileType.includes("image") ?
                          <img src={file.imageUrl} />
                          : <a href={file.imageUrl}>Ladda ner fil</a>
                  }
                  {
                    comments && comments.filter(c => (c.missionId === mission.id! && c.playerId === x)).map(y => {
                      return (<>
                        <h3>Kommentar</h3>
                        <p>{y.comment}</p>
                      </>)
                    })
                  }
                </div>
                :
                <div>
                  {
                    comments && comments.filter(c => (c.missionId === mission.id! && c.playerId === x)).map(y => {
                      return (<>
                        <h3>Kommentar</h3>
                        <p>{y.comment}</p>
                      </>)
                    })
                  }
                </div>
            )
          })
        }


      </div>
    </div>
  );
};