import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store/store';
import { Player } from '../../store/types';

interface PlayersState {
  players: Player[];
};

const initialState: PlayersState = {
  players: [],
};

const playersSlice = createSlice({
  name: 'player',
  initialState,
  reducers: {
    setPlayers: (
      state,
      action: PayloadAction<Player[]>
    ) => {
      state.players = action.payload;
    },
  }
});

export const { setPlayers } = playersSlice.actions;
export const selectPlayers = (state: RootState) => state.rootReducer.players;
export default playersSlice.reducer;