import { Button, Checkbox, Divider, Select, Upload, UploadFile } from "antd";
import { useState } from "react";
import { IAssignMission, Mission } from "../../store/types";
import { UploadOutlined } from '@ant-design/icons';
import TextArea from "antd/es/input/TextArea";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store/store";
import { DraggableList } from "./DraggableList";
import { AssignMission } from "../../actions/MissionsActions";
import { useAppSelector } from "../../store/hooks";
import { selectPlayers } from "../../features/players/playersSlice";
import { resetLoading, selectAuth, setLoading } from "../../features/user/authSlice";
import { getNotificationTemplate } from "./NotificationTemplate";
import { CreateNotification } from "../../actions/NotificationActions";

export const AssignMissionTemplate = ({
  mission,
  onAssigned
}: {
  mission: Mission;
  onAssigned: (response: string) => void;
}) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const dispatch = useDispatch<AppDispatch>();
  const players = useAppSelector(selectPlayers);
  const auth = useAppSelector(selectAuth);
  const handleFileChange = ({ fileList }: { fileList: UploadFile[] }) => {
    setFileList(fileList);
  };
  const [isMissionOneByOne, setIsMissionOneByOne] = useState(false);
  const [playerMissionOrder, setPlayerOrder] = useState<number[]>([]);
  const [assignedPlayers, setAssignedPlayers] = useState<number[]>([]);

  const handlePlayerSelectionChange = (values: any) => {
    const player: number[] = [];
    const type = typeof (values);
    if (type === "number") {
      player.push(values);
      setAssignedPlayers(player);
    } else {
      setAssignedPlayers(values);
    }
  }

  const handleSubmit = async () => {
    const assignedMission: IAssignMission = {
      missionId: mission.id!,
      playerOrder: isMissionOneByOne,
      assignedToType: mission.assignedToType,
      assignedTo: mission.assignedToType < 2 ? assignedPlayers : isMissionOneByOne ? playerMissionOrder : players.players.map(x => x.id!)
    }
    dispatch(setLoading());
    const response = await AssignMission(assignedMission);
    dispatch(resetLoading());
    if (response) {
      const notification = getNotificationTemplate(response, assignedMission.assignedTo, auth.user!, players.players);
      notification && CreateNotification(notification);
      onAssigned(response);
    }

  }

  const renderTextWithLinks = (text: string, hyperlinkText: string) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.split(' ').map((word, index) => {
      if (urlRegex.test(word)) {
        return (
          <>
            <br />
            <br />
            <a key={index} href={word.trim()} target="_blank" rel="noopener noreferrer">
              {hyperlinkText}
            </a>
            <br />
            <br />
          </>
        );
      }
      return <span key={index}>{word} </span>;
    });
  };

  return (
    <div className="collapse-item-child">
      <p>{mission.description !== "" && renderTextWithLinks(mission.description, "Spela här")}</p>
      <div className="collapse-item-image">
        {
          mission.files && mission.files.map(x => {
            return <img src={x.imageUrl} />
          })
        }
      </div>
      <div className="hand-in-form">
        <Divider style={{ borderColor: "#cfb193" }} dashed />
        <h4>Svara på uppdraget</h4>
        <h3>Lämna kommentar</h3>
          <div className="user-comment-wrapper">
            <TextArea className="user-comment">
            </TextArea>
          </div>
        {
          mission.userUpload &&
          <Upload
            name="file"
            listType="picture"
            maxCount={1}
            beforeUpload={() => false}
            fileList={fileList}
            onChange={handleFileChange}
          >
            <Button type="default" icon={<UploadOutlined />}>Ladda upp fil</Button>
          </Upload>
        }
        <Button type="default" onClick={() => console.log("")} style={{ marginTop: "15px" }} >Skicka</Button>
      </div>
      <Divider style={{ borderColor: "#cfb193" }} dashed />
      <div className="player-selection-section">
        <p>{mission.assignedToType === 0 ? "En spelare" : mission.assignedToType === 1 ? "Flera spelare" : "Alla spelare"}</p>
        {
          mission.assignedToType !== 2 ?
          <div className="player-selection-dropdown">
            <Select
              mode={mission.assignedToType === 0 ? undefined : mission.assignedToType === 1 ? "multiple" : undefined}
              onChange={handlePlayerSelectionChange}
            >
              {
                players && players.players.map(x => <Select.Option value={x.id}>{x.username}</Select.Option>)
              }
            </Select>
            </div> :
            mission.assignedToType === 2 ?
              <div className="all-mission-section">
                <Checkbox checked={isMissionOneByOne} onChange={() => setIsMissionOneByOne(!isMissionOneByOne)}>
                  Ska uppdraget utföras i turordning?
                </Checkbox>
                {isMissionOneByOne ?
                  <>
                    <p>Bestäm ordning</p>
                    <DraggableList droppableId={mission.id!} playerList={players.players} onOrderChanged={(playerOrder: number[]) => setPlayerOrder(playerOrder)} />
                  </>
                  :
                  <></>
                }
              </div>
              :
              <></>
        }
        <Button onClick={handleSubmit}>Tilldela uppdrag</Button>
      </div>
    </div>
  );
};