import { Button, Form, FormInstance, Input, message } from "antd";
import React from "react";
import { CreatePlayer } from "../../actions/PlayerActions";
import { register } from "../../features/user/authApi";
import { resetLoading, setLoading } from "../../features/user/authSlice";
import { useAppDispatch } from "../../store/hooks";

interface CreateUserValues {
  username: string;
  email: string;
  password: string;
  firstName: string;
  lastName: string;
}

export const CreateUser = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const dispatch = useAppDispatch();
  const formRef = React.useRef<FormInstance>(null);

  const showSuccess = () => {
    messageApi.open({
      type: 'success',
      content: 'Användare skapad',
      duration: 3,
    });
  };
  const handleSubmit = async (values: CreateUserValues) => {
    dispatch(setLoading());
    const createdPlayer = await CreatePlayer(values);
    const resp = createdPlayer && createdPlayer.id && await register(values.username, values.email, values.password, createdPlayer.id);
    if(resp && createdPlayer) {
      dispatch(resetLoading());
      formRef.current?.resetFields();
      showSuccess();
    }
  };
  return (
    <div className="content-box">
      {contextHolder}
      <h1>Skapa användare</h1>
      <Form
        name="create-user-form"
        id="create-user-form"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        initialValues={{ remember: true }}
        onFinish={handleSubmit}
        autoComplete="create-mission-form"
        ref={formRef}
      >
        <Form.Item<CreateUserValues>
          label="Användarnamn"
          name="username"
          rules={[{ required: true, message: "Ange användarnamn" }]}
        >
          <Input
            name="create-user-form-userName"
            id="crate-user-form-userName"
            autoComplete="create-user-form-userName"
          />
        </Form.Item>
        <Form.Item<CreateUserValues>
          label="E-mail"
          name="email"
          rules={[{ required: true, message: "Ange e-mail" }]}
        >
          <Input
            name="create-user-form-email"
            id="crate-user-form-email"
            autoComplete="create-user-form-email"
          />
        </Form.Item>
        <Form.Item<CreateUserValues>
          label="Förnamn"
          name="firstName"
          rules={[{ required: true, message: "Ange förnamn" }]}
        >
          <Input
            name="create-user-form-firstName"
            id="crate-user-form-firstName"
            autoComplete="create-user-form-firstName"
          />
        </Form.Item>
        <Form.Item<CreateUserValues>
          label="Efternamn"
          name="lastName"
          rules={[{ required: true, message: "Ange efternamn" }]}
        >
          <Input
            name="create-user-form-lastName"
            id="crate-user-form-lasatName"
            autoComplete="create-user-form-lastName"
          />
        </Form.Item>
        <Form.Item<CreateUserValues>
          label="Lösenord"
          name="password"
          rules={[{ required: true, message: "Ange lösenord" }]}
        >
          <Input.Password
            name="create-user-form-password"
            id="crate-user-form-password"
            autoComplete="create-user-form-password"
          />
        </Form.Item>
        <Form.Item wrapperCol={{ span: 16 }} className="submit-container">
            <Button type="primary" htmlType="submit">
              Skapa
            </Button>
          </Form.Item>
      </Form>
    </div>  
  )
};