import axiosInstance from "../api/axios";
import { iAppResponse } from "../store/appResponse";
import { Notification, NotificationRequest, Player }from "../store/types";

export const CreateNotification = async (values: NotificationRequest) => {
  try {
    const response = await axiosInstance.post<iAppResponse<boolean>>(
      'api/notification/CreateNotification',
      values
    );
    if (response.data) {
      return response.data;
    }
  } catch (ex) {
    console.error(ex);
    return undefined;
  }
};

export const GetNotifications = async (playerId: number) => {
  try {
    const response = await axiosInstance.get<iAppResponse<Notification[]>>(`api/Notification/GetNotifications/${playerId}`);
    if (!response.data) {
      throw new Error('Failed to fetch notifications');
    }
    return response.data as unknown as Notification[];
  } catch (ex) {
    console.error(ex);
    return undefined;
  }
};

export const MarkNotificationAsRead = async (id: number) => {
  try {
    const response = await axiosInstance.post<iAppResponse<boolean>>(`api/Notification/MarkAsRead?id=${id}`);
    if (!response.data) {
      throw new Error('Failed to fetch notifications');
    }
    
    return response.data;
  } catch (ex) {
    console.error(ex);
    return undefined;
  }
};

export const CreateNotificationForAllExceptAssignedTo = async (playersList: Player[], message: string) => {
  const values: NotificationForAllExceptAssignedTo = {
    playersList: playersList,
    message: message
  }
  try {
    const response = await axiosInstance.post<iAppResponse<boolean>>(
      'api/notification/CreateNotificationForAllExceptAssignedTo',
      values
    );
    if (response.data) {
      return response.data;
    }
  } catch (ex) {
    console.error(ex);
    return undefined;
  }
  return true;
}

interface NotificationForAllExceptAssignedTo {
  playersList: Player[];
  message: string;
}