import axiosInstance from "../api/axios";
import { ICorrectSecretMissionValues } from "../components/adminpages/CorrectMission";
import { iAppResponse } from "../store/appResponse";
import { SecretMission } from "../store/types";

export const AddSecretMission = async (values: SecretMission) => {
  try {
    const response = await axiosInstance.post<iAppResponse<boolean>>(
      '/api/SecretMission/AddSecretMission',
      values
    );
    return response.data;
  } catch (ex) {
    console.error(ex);
    return undefined
  }
}

export const GetSecretMissions = async () => {
  const response = await axiosInstance.get("api/SecretMission/GetSecretMissions");
  if (!response.data) {
    throw new Error('Failed to fetch missions');
  }
  return response.data;
}

export const CorrectSecretMission = async (values: ICorrectSecretMissionValues[]): Promise<iAppResponse<string> | undefined> => {
  const formData = new FormData();

  values.forEach((form, index) => {
    // Append the playerId, points, and bonusPoints
    formData.append(`CluesAndPoints[${index}].missionId`, form.missionId.toString());
    formData.append(`CluesAndPoints[${index}].playerId`, form.playerId.toString());
    formData.append(`CluesAndPoints[${index}].points`, form.points);
    formData.append(`CluesAndPoints[${index}].bonusPoints`, form.bonusPoints);

    // Append the file separately
    if (form.file) {
      formData.append(`CluesAndPoints[${index}].file`, form.file as unknown as File);
    }
  });

  try {
    const response = await axiosInstance.post<iAppResponse<string>>(
      '/api/SecretMission/CorrectSecretMission',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return response.data;
  } catch (ex) {
    console.error(ex);
    return undefined;
  }
};