import React from 'react';
import Icon from '@ant-design/icons';

const BagIconSvg = () => (

  <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 24 24" version="1.1">
  
  <g>
        <path fill="none" d="M0 0H24V24H0z"/>
        <path d="M18 23h-2v-1H8v1H6v-1H5c-1.105 0-2-.895-2-2V7c0-1.105.895-2 2-2h3V3c0-.552.448-1 1-1h6c.552 0 1 .448 1 1v2h3c1.105 0 2 .895 2 2v13c0 1.105-.895 2-2 2h-1v1zm1-16H5v13h14V7zm-9 2v9H8V9h2zm6 0v9h-2V9h2zm-2-5h-4v1h4V4z"/>
    </g>
  </svg>
);

const BagIcon = (props: any) => <Icon className="bag-icon" component={BagIconSvg} {...props} />;

export default BagIcon;