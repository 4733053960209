import axiosInstance from "../api/axios"
import { ICreateGameValues, IJoinGameValues } from "../components/templates/Maffia";

export const GetGameState = async () => {
    const response = await axiosInstance.get("/api/Mafia/status");

    return response;
}

export const CreateGame = async (values: ICreateGameValues) => {
    const response = axiosInstance.post("/api/Mafia/create", values);

    return response;
}

export const JoinGame = async (values: IJoinGameValues) => {
    const response = axiosInstance.post("/api/Mafia/join", values);

    return response;
}

export const StartGame = async () => {
    const response = axiosInstance.get("/api/Mafia/start");

    return response;
}

export const GetRole = async (playerId: number) => {
    
    try {
    const response = axiosInstance.get(`/api/Mafia/role/${playerId}`);
    return response;
} catch {
    return 
}
  
}

export const ConfirmRole = async (playerId: number) => {
    console.log("CONFIRMING ROLE");
    const response = axiosInstance.get(`/api/Mafia/confirm-role/${playerId}`);
    console.log("RESPONSE", response);
    return response;
}

export const Assassinate = async (playerId: number, targetId: number) => {
    const response = axiosInstance.post("/api/Mafia/assassinate", { playerId, targetId });
    return response;
}

export const Save = async (playerId: number, targetId: number) => {
    const response = axiosInstance.post("/api/Mafia/save", { playerId, targetId });
    return response;
}

export const Check = async(playerId: number, targetId: number) => {
    const response = axiosInstance.post("/api/Mafia/check", { playerId, targetId });

    return response;
}

export const CheckResult = async() => {
    const response = axiosInstance.get("/api/Mafia/check-result");
    console.log("RESPONSE", response);
    return response;
}

export const CheckConfirm = async (playerId: number) => {
    const response = axiosInstance.get(`/api/Mafia/check-confirm/${playerId}`);
    console.log("RESPONSE", response);
    return response;
}

export const Sleep = async () => {
    const response = axiosInstance.get("/api/Mafia/sleep");
    return response;
}
export const DoctorWake = async () => {
    const response = axiosInstance.get("/api/Mafia/doctor-wakeup");
    return response;
}
export const SheriffWake = async () => {
    const response = axiosInstance.get("/api/Mafia/sheriff-wakeup");
    return response;
}
export const EndGame = async () => {
    const response = axiosInstance.get("/api/Mafia/end");

    return response;
}

export const Results = async () => {
    const response = await axiosInstance.get("/api/Mafia/assassinate-result");

    return response;
}