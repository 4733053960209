import { IGameState } from "./Maffia";
import bynAsleep from "../../assets/sound/byn-somnar-in.m4a";
import mafiaAwakes from "../../assets/sound/maffia-vaknar.m4a";
import mafiaAsleep from "../../assets/sound/maffia-somnar-in.m4a";
import doctorAwakes from "../../assets/sound/läkare-vaknar.m4a";
import doctorAsleep from "../../assets/sound/läkare-somnar-in.m4a";
import sheriffAwakes from "../../assets/sound/sheriff-vaknar.m4a";
import sherrifAsleep from "../../assets/sound/sheriff-somnar-in.m4a";
import bynAwakes from "../../assets/sound/byn-vaknar-upp.m4a";
import ahsen from "../../assets/sound/Åhsen.m4a";
import anti from "../../assets/sound/Anti.m4a";
import bonan from "../../assets/sound/Bönan.m4a";
import davan from "../../assets/sound/Davan.m4a";
import gurra from "../../assets/sound/Gurra.m4a";
import skuggan from "../../assets/sound/Skuggan.m4a";
import strand from "../../assets/sound/Strand.m4a";
import wallin from "../../assets/sound/Wallin.m4a";
import mord from "../../assets/sound/mord.m4a";
import mordForsok from "../../assets/sound/mordförsök.m4a";
import arMordad from "../../assets/sound/är-mördad.m4a";
import { MouseEventHandler, useEffect, useRef, useState } from "react";
import { ScriptElementKindModifier } from "typescript";
import Button from "antd/es/button";
import { Assassinate, Check, CheckConfirm, DoctorWake, Results, Save, SheriffWake, Sleep } from "../../actions/MaffiaActions";
// {
//   "murderSuccessful": true,
//   "target": {
//     "id": 2,
//     "name": "Master"
//   }
// }
// export interface IAssassinateResults {
//   murderSuccessful: boolean;
//   target: {
//     id: number;
//     name: string;
//   }
// }
export const MaffiaGame = ({ game, gamePhase, playerId, role }: { game: IGameState; gamePhase: string; playerId: number, role: string }) => {
  const bynAsleepRef = useRef<HTMLAudioElement | null>(null);
  const mafiaAwakesRef = useRef<HTMLAudioElement | null>(null);
  const mafiaAsleepRef = useRef<HTMLAudioElement | null>(null);
  const doctorAwakesRef = useRef<HTMLAudioElement | null>(null);
  const doctorAsleepRef = useRef<HTMLAudioElement | null>(null);
  const sheriffAwakesRef = useRef<HTMLAudioElement | null>(null);
  const sheriffAsleepRef = useRef<HTMLAudioElement | null>(null);
  const bynAwakesRef = useRef<HTMLAudioElement | null>(null);
  const ahsenRef = useRef<HTMLAudioElement | null>(null);
  const antiRef = useRef<HTMLAudioElement | null>(null);
  const bonanRef = useRef<HTMLAudioElement | null>(null);
  const davanRef = useRef<HTMLAudioElement | null>(null);
  const gurraRef = useRef<HTMLAudioElement | null>(null);
  const skugganRef = useRef<HTMLAudioElement | null>(null);
  const strandRef = useRef<HTMLAudioElement | null>(null);
  const wallinRef = useRef<HTMLAudioElement | null>(null);
  const mordRef = useRef<HTMLAudioElement | null>(null);
  const mordForsokRef = useRef<HTMLAudioElement | null>(null);
  const arMordadRef = useRef<HTMLAudioElement | null>(null);

  const [soundAccepted, setSoundAccepted] = useState(false);
  const [soundSource, setSoundSource] = useState(bynAsleep);
  const [delay, setDelay] = useState(false);
  const [timer, setTimer] = useState<number>();
  const [DoctorPlayed, setDoctorPlayed] = useState(false);
  const [SheriffPlayed, setSheriffPlayed] = useState(false);
  const [villageWakesPlayed, setVillageWakesPlayed] = useState(false)
  const [assasinationResults, setAssasionationResults] = useState();
  const Delay = (time: number, sound: string) => {
    const timeout = setTimeout(() => {
      setDelay(false);
      playSound(sound);
    }, time);  // Delay specified by 'timer' prop

    // Cleanup function to clear timeout if component unmounts or 'timer' changes
    return () => clearTimeout(timeout);
  }


  const playSound = async (sound: string) => {
    if (playerId === game.gameMaster) {
      sound === "bynAsleep" && await bynAsleepRef.current?.play();
      sound === "mafiaAwakes" && await mafiaAwakesRef.current?.play();
      sound === "mafiaAsleep" && await mafiaAsleepRef.current?.play();
      sound === "doctorAwakes" && await doctorAwakesRef.current?.play();
      sound === "doctorAsleep" && await doctorAsleepRef.current?.play();
      sound === "sheriffAwakes" && await sheriffAwakesRef.current?.play();
      sound === "sheriffAsleep" && await sheriffAsleepRef.current?.play();
      sound === "bynAwakes" && await bynAwakesRef.current?.play();
      sound === "27" && await ahsenRef.current?.play();
      sound === "30" && await antiRef.current?.play();
      sound === "23" && await bonanRef.current?.play();
      sound === "25" && await davanRef.current?.play();
      sound === "29" && await gurraRef.current?.play();
      sound === "24" && await skugganRef.current?.play();
      sound === "28" && await strandRef.current?.play();
      sound === "0" && await wallinRef.current?.play();
      sound === "2" && await wallinRef.current?.play();
      sound === "mord" && await mordRef.current?.play();
      sound === "mordForsok" && await mordForsokRef.current?.play();
      sound === "arMordad" && await arMordadRef.current?.play();
    }
  };

  const goToSleep = () => {
    setSoundAccepted(true)
    playSound("bynAsleep")
    //setDelay(true);
    // setSoundSource(mafiaAwakes);
    Delay(7000, "mafiaAwakes");

    setTimeout(() => Sleep(), 12000);
  }

  const doctorWakes = () => {
    //setSoundAccepted(true)
    setDoctorPlayed(true);
    playSound("mafiaAsleep")
    //setDelay(true);
    // setSoundSource(mafiaAwakes);
    Delay(7000, "doctorAwakes");
    setTimeout(() => DoctorWake(), 12000);
  }

  const sheriffWakes = () => {
    setSheriffPlayed(true);
    //setSoundAccepted(true)
    playSound("doctorAsleep")
    //setDelay(true);
    // setSoundSource(mafiaAwakes);
    Delay(7000, "sheriffAwakes");
    setTimeout(() => SheriffWake(), 12000);
  }

  const villageWakes = async () => {
    playSound("sheriffAsleep");
    setVillageWakesPlayed(true);
    //setSoundAccepted(true)
    Delay(5000, "bynAwakes");
    setDelay(true);
    // setSoundSource(mafiaAwakes);
    // Delay(12000, "bynAwakes");
    const assassinateResults = await Results();
    console.log("RES", assassinateResults);
    if (assassinateResults && assassinateResults.data.murderSuccessful) {
      Delay(12000, "mord");
      Delay(15000, assassinateResults.data.target.id.toString());
      Delay(17000, "arMordad");
    } else {
      Delay(10000, "mordForsok");
    }


  }

  const handleAssassinate = (playerId: number, targetId: number) => {
    console.log(`Player ${playerId} is attempting to assassinate Player ${targetId}`);
    // Add your logic here to handle the assassination attempt
    Assassinate(playerId, targetId);
  }

  const handleSave = (playerId: number, targetId: number) => {
    console.log(`Player ${playerId} is attempting to save Player ${targetId}`);
    // Add your logic here to handle the saving attempt

    Save(playerId, targetId);

  }

  const handleCheck = (playerId: number, targetId: number) => {
    console.log(`Player ${playerId} is attempting to save Player ${targetId}`);
    // Add your logic here to handle the saving attempt

    return Check(playerId, targetId);
  }

  const handleCheckConfirm = (playerId: number) => {
    console.log(`Player ${playerId} is confirming the check`);
    // Add your logic here to handle the saving attempt

    return CheckConfirm(playerId);
  }

  useEffect(() => {
    if (playerId === game.gameMaster && gamePhase === "PreDoctor" && !DoctorPlayed) {

      doctorWakes();
    }

    if (playerId === game.gameMaster && gamePhase === "PreSheriff" && !SheriffPlayed) {
      sheriffWakes();
    }

    if (playerId === game.gameMaster && gamePhase === "Wakeup" && !villageWakesPlayed) {
      villageWakes();
    }
  })

  return (
    <div id="foc">
      <p>{delay}</p>
      <audio ref={bynAsleepRef} src={bynAsleep} />
      <audio ref={mafiaAwakesRef} src={mafiaAwakes} />
      <audio ref={mafiaAsleepRef} src={mafiaAsleep} />
      <audio ref={doctorAwakesRef} src={doctorAwakes} />
      <audio ref={doctorAsleepRef} src={doctorAsleep} />
      <audio ref={sheriffAwakesRef} src={sheriffAwakes} />
      <audio ref={sheriffAsleepRef} src={sherrifAsleep} />
      <audio ref={bynAwakesRef} src={bynAwakes} />
      <audio ref={ahsenRef} src={ahsen} />
      <audio ref={antiRef} src={anti} />
      <audio ref={bonanRef} src={bonan} />
      <audio ref={davanRef} src={davan} />
      <audio ref={gurraRef} src={gurra} />
      <audio ref={skugganRef} src={skuggan} />
      <audio ref={strandRef} src={strand} />
      <audio ref={wallinRef} src={wallin} />
      <audio ref={mordRef} src={mord} />
      <audio ref={mordForsokRef} src={mordForsok} />
      <audio ref={arMordadRef} src={arMordad} />
      {/* <button onClick={playSound}>Play Sound</button> */}
      {playerId === game.gameMaster && gamePhase === "Night" && !soundAccepted &&
        <Button type="primary" htmlType="submit" onClick={() =>
          goToSleep()
        } >
          Start
        </Button>
      }

      {
        gamePhase === "Maffia" && role === "Maffia" && bynAsleepRef.current!.played &&
        <>
          <h2>Vem ska dö?</h2>
          {
            // send the id of the player and the id of the target to handleAssassinate
            <div className="assassin-selection">
              {

                game.players.map(p =>
                  p.id !== playerId &&

                  <Button
                    type="primary"
                    id={p.id.toString()}
                    htmlType="submit"
                    onClick={() => handleAssassinate(playerId, p.id)}
                  >
                    {p.name}
                  </Button>
                )
              }
            </div>
          }
        </>
      }
      {
        gamePhase === "Maffia" && role !== "Maffia" &&
        <>
          <h2>Inget händer för dig</h2>
        </>
      }
      {
        gamePhase === "Doctor" && role === "Läkare" &&
        <>
          <h2>Vem ska ni rädda?</h2>
          {
            // send the id of the player and the id of the target to handleSave
            <div className="assassin-selection">
              {
                game.players.map(p =>
                  p.id !== playerId &&
                  <Button
                    type="primary"
                    id={p.id.toString()}
                    htmlType="submit"
                    onClick={() => handleSave(playerId, p.id)}
                  >
                    {p.name}
                  </Button>
                )
              }
            </div>
          }
        </>
      }
      {
        gamePhase === "Doctor" && role !== "Läkare" &&
        <>
          <h2>Inget händer för dig</h2>
        </>
      }
      {
        gamePhase === "Sheriff" && role === "Sheriff" &&
        <>
          <h2>Vem ska ni kolla?</h2>
          {
            // send the id of the player and the id of the target to handleCheck
            <div className="assassin-selection">
              {

                game.players.map(p =>
                  p.id !== playerId &&
                  <Button
                    type="primary"
                    id={p.id.toString()}
                    htmlType="submit"
                    onClick={() => handleCheck(playerId, p.id)}
                  >
                    {p.name}
                  </Button>
                )
              }
            </div>
          }
        </>
      }
      {
        gamePhase === "Sheriff" && role !== "Sheriff" &&
        <>
          <p>{gamePhase}</p>
          <p>{role}</p>
          <h2>Inget händer för dig</h2>
        </>
      }

    </div>
  );
};

const Timer = ({ onIntroEnd }: { onIntroEnd: () => void }) => {
  const [secondsLeft, setSecondsLeft] = useState<number>(5);
  useEffect(() => {
    // Function to fetch the mission's end time from the server


    // Fetch the end time initially

    // Set an interval to update the remaining time every second
    const intervalId = setInterval(() => {
      setSecondsLeft((prevSecondsLeft) => {
        if (prevSecondsLeft > 0) {
          return prevSecondsLeft - 1;
        } else {
          onIntroEnd();
          clearInterval(intervalId); // Stop the timer when it reaches zero
          return 0;
        }
      });
    }, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  return <h1>{secondsLeft}</h1>
};