import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store/store";
import { jwtDecode } from "jwt-decode";
import { logout } from "./authApi";

export interface iUser {
  RoleClaim: Array<string>;
  UserName: string;
  PleyerId: string;
}

export interface iAuthState {
  status: "idle" | "loading" | "failed";
  accessToken?: string;
  refreshToken?: string;
  user?: iUser;
}

const initialState: iAuthState = {
  status: "idle",
};

// Thunk for handling logout
export const logoutAsync = createAsyncThunk("user/logout", async () => {
  const response = await logout();
  return response?.data;
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    // Reducer for updating the token and storing it in localStorage
    updateToken: (
      state,
      action: PayloadAction<{ accessToken: string; refreshToken: string }>
    ) => {
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
      state.user = jwtDecode<iUser>(action.payload.accessToken);

      // Store tokens in localStorage
      localStorage.setItem("accessToken", action.payload.accessToken);
      localStorage.setItem("refreshToken", action.payload.refreshToken);
    },

    // Reducer for clearing tokens
    resetToken: (state) => {
      state.accessToken = undefined;
      state.refreshToken = undefined;
      state.user = undefined;

      // Remove tokens from localStorage
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
    },

    // Setting loading state
    setLoading: (state) => {
      state.status = "loading";
    },

    resetLoading: (state) => {
      state.status = "idle";
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(logoutAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(logoutAsync.fulfilled, (state) => {
        state.status = "idle";
        state.accessToken = undefined;
        state.refreshToken = undefined;
        state.user = undefined;

        // Remove tokens from localStorage on logout
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
      })
      .addCase(logoutAsync.rejected, (state) => {
        state.status = "failed";
      });
  },
});

// Action creators
export const {updateToken, resetToken, setLoading, resetLoading} = authSlice.actions;

// Selector to get auth state
export const selectAuth = (state: RootState) => state.rootReducer.auth;

export default authSlice.reducer;
