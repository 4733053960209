import { selectAuth } from "../features/user/authSlice";
import { useAppSelector } from "../store/hooks";

export const HomePage = () => {
  const auth = useAppSelector(selectAuth);

  return (
    <>
      {!auth.user && <h3 className={"intro-text"}>Uppesittarresan.</h3>}
    </>
  );
};