import { App, Button, Drawer, Layout, Menu, message } from "antd";
import { Content, Header } from "antd/es/layout/layout";
import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router";
import { logoutAsync, resetLoading, selectAuth, setLoading } from "../../features/user/authSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import BackGroundImage from "../BackgroundImage";
import { MenuOutlined, LaptopOutlined, ProfileOutlined, HomeOutlined, QuestionCircleOutlined, TrophyOutlined, BuildOutlined, LogoutOutlined } from '@ant-design/icons';
import BagIcon from "../../assets/Icons/BagIcon";
import MapIcon from "../../assets/Icons/MapIcon";
import Notifications from "../notification/Notifications";
import { SecretMission } from "../../store/types";
import { AddSecretMission, GetSecretMissions } from "../../actions/SecretMissionActions";

export const UserLayout = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const auth = useAppSelector(selectAuth);
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [secretMissions, setSecretMissions] = useState<SecretMission[]>();
  const [current, setCurrent] = useState(
    location.pathname === "/" || location.pathname === ""
      ? "/"
      : location.pathname
  );

  const showSuccess = () => {
    messageApi.open({
      type: 'success',
      content: 'Du har utfört ett hemligt uppdrag! Poäng och ledtråd kommer snart!',
      duration: 5,
    });
  };

  const SecretMissions = async () => {
    dispatch(setLoading());
    const secretMissions = await GetSecretMissions();
    dispatch(resetLoading());
    if (secretMissions) {
      setSecretMissions(secretMissions.data);
    }
  }

  useEffect(() => {
    SecretMissions();
  }, []);
  useEffect(() => {
    if (location) {
      if (current !== location.pathname) {
        setCurrent(location.pathname);
      }
    }
  }, [location]);

  const handleClick = (key: string) => {
    onClose();
    navigate(key);
  };

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };
  const submitSecretMission = async (key: string) => {
    const request: SecretMission = {
      missionKey: key,
      foundBy: parseInt(auth.user!.PleyerId),
      isCorrected: false
    }
    dispatch(setLoading());

    if (auth.user) {
      const data = await AddSecretMission(request);
      dispatch(resetLoading());
      if (data?.data) {
        showSuccess();
        SecretMissions();
      }
    } else {
      dispatch(resetLoading());
    }
  };

  return (
    <App style={{ height: "100%" }}>
      <Layout className="layout">
        {contextHolder}
        <Header style={{ display: "flex", alignItems: "center" }} className={auth.user ? "header with-logo" : "header"}>
          {secretMissions ? 
            secretMissions.find(x => x.missionKey === "header" && x.foundBy === parseInt(auth.user!.PleyerId)) ? <></> : <p className="secret-1" onClick={(e) => { submitSecretMission("header") }}>HEMLIGT</p>
            : <p className="secret-1" onClick={(e) => { submitSecretMission("header") }}>HEMLIGT</p>
          }          
          {auth.user && <h3 className="header-logo">Uppesittarresan.</h3>}
          <div className="header-right-wrapper">
            <div className="notification">
              <Notifications />
            </div>
            <Button
              type="primary"
              icon={<MenuOutlined />}
              onClick={showDrawer}
              style={{ position: 'fixed', top: 16, left: 16, zIndex: 1000 }}
              className="menu-button"
            />
          </div>
          <Drawer
            placement="right"
            closable={true}
            onClose={onClose}
            visible={visible}
            className="menu-drawer"
          >
            <Menu
              mode="inline"
              defaultSelectedKeys={["/"]}
              selectedKeys={[current]}
              style={{ minWidth: "500px" }}
              items={[
                {
                  key: "/",
                  label: "Hem",
                  icon: <HomeOutlined />,
                  onClick: (e) => {
                    handleClick(e.key);
                  }
                },
                {
                  key: "/maffia",
                  label: "Mafia",
                  icon: <HomeOutlined />,
                  onClick: (e) => {
                    handleClick(e.key);
                  }
                },
                {
                  key: "/tap-game",
                  label: "Klickspelet",
                  icon: <LaptopOutlined />,
                  onClick: (e) => {
                    handleClick(e.key);
                  }
                },
                {
                  key: "/my-missions",
                  label: "Mina uppdrag",
                  icon: <ProfileOutlined />,
                  onClick: (e) => {
                    handleClick(e.key);
                  }
                },
                {
                  key: "/my-clues",
                  label: "Mina ledtrådar",
                  icon: <BuildOutlined />,
                  onClick: (e) => {
                    handleClick(e.key);
                  }
                },
                {
                  key: "/scores",
                  label: "Poängställning",
                  icon: <TrophyOutlined />,
                  onClick: (e) => {
                    handleClick(e.key);
                  }
                },
                {
                  key: "/packing-list",
                  label: "Packlista",
                  icon: <BagIcon />,
                  onClick: (e) => {
                    handleClick(e.key);
                  }
                },
                {
                  key: "/guess-destination",
                  label: "Gissa resmål",
                  icon: <MapIcon />,
                  onClick: (e) => {
                    handleClick(e.key);
                  }
                },
                {
                  key: "/faq",
                  label: "Frågor & svar",
                  icon: <QuestionCircleOutlined />,
                  onClick: (e) => {
                    handleClick(e.key);
                  }
                },
                {
                  key: "/logout",
                  label: "Logga ut",
                  icon: <LogoutOutlined />,
                  onClick: () => {
                    dispatch(logoutAsync());
                    handleClick("/");
                  }
                },
              ]}
            />
          </Drawer>
        </Header>
        <Content style={{ padding: "0 50px", minHeight: "400px", height: "100%" }}>
          <div className="content">
            <Outlet />
          </div>
        </Content>
        <BackGroundImage />
      </Layout>
    </App>
  );
};