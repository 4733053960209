import React from 'react';
import Icon from '@ant-design/icons';

const MapIconSvg = () => (

  <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 24 24" version="1.1">
<g id="map-location">
	<path className="st0" d="M18.3,4.4C17.4,1.8,14.9,0,12,0C8.8,0,6.1,2.3,5.4,5.3L0,3v17.2l8,3.4l8-3l8,3.4V6.8L18.3,4.4z M9,14.4
		c1.2,1.4,2.3,2.2,2.4,2.3l0.7,0.6l0.6-0.6c0.3-0.3,0.7-0.7,1.1-1c0.4-0.4,0.8-0.8,1.2-1.2v4.3l-6,2.3V14.4z M12,2
		c2.6,0,4.8,2.1,4.8,4.8c0,1.9-0.8,3.4-1.8,4.7l0,0l0,0c-0.8,1-1.7,1.9-2.6,2.7c-0.1,0.1-0.3,0.3-0.4,0.4c-1.8-1.6-4.8-5-4.8-7.8
		C7.2,4.1,9.4,2,12,2z M2,6l3.3,1.4c0.2,1.5,0.9,3,1.7,4.4V21l-5-2.1V6z M22,21l-5-2.1v-6.7c1-1.5,1.8-3.2,1.8-5.4L22,8.2V21z"/>
	<circle className="st0" cx="12" cy="7" r="2"/>
</g>
  </svg>
);

const MapIcon = (props: any) => <Icon className="bag-icon" component={MapIconSvg} {...props} />;

export default MapIcon;