import axiosInstance from "../api/axios";
import { AnswerValues } from "../components/userpages/Faq";
import { FAQ } from "../store/types";

export const CreateQuestion = async (question: FAQ) => {
  const response = await axiosInstance.post<FAQ>("api/faq/CreateQuestion", question)
    .catch((ex) => {
      console.log(ex);
    });
  return response?.data;
}

export const GetQuestions = async () => {
  const response = await axiosInstance.get("api/faq/GetQuestions");
  if (!response.data) {
    throw new Error('Failed to fetch questions');
  }
  return response.data.data as FAQ[];
}

export const AnswerQuestion = async (values: AnswerValues) => {
  const response = await axiosInstance.post<boolean>("api/faq/AnswerQuestion", values)
  if (!response.data) {
    throw new Error('Failed to answer question');
  }
  return response;
}