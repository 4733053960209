import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { UploadOutlined } from '@ant-design/icons';
import { AppDispatch } from "../../store/store";
import { Button, Collapse, CollapseProps, Form, Input, message, Upload, UploadFile } from "antd";
import { ITapGame, Mission, MissionComment, Player, SecretMission } from "../../store/types";
import { GetPendingMissions } from "../../actions/MissionsActions";
import { CorrectMissionTemplate } from "../templates/CorrectMissionTemplate";
import { CaretRightOutlined } from '@ant-design/icons';
import { useAppSelector } from "../../store/hooks";
import { selectPlayers } from "../../features/players/playersSlice";
import { resetLoading, selectAuth, setLoading } from "../../features/user/authSlice";
import { GetMissionComments } from "../../actions/CommentActions";
import { CorrectSecretMission, GetSecretMissions } from "../../actions/SecretMissionActions";
import { CorrectTapGame, GetPendingTapGames } from "../../actions/TapGameActions";
import { getNotificationTemplate } from "../templates/NotificationTemplate";
import { CreateNotification } from "../../actions/NotificationActions";

export interface ICorrectSecretMissionValues {
  missionId: number;
  playerId: number;
  file: UploadFile;
  points: string;
  bonusPoints: string;
}

export interface ICorrectTapGameValues {
  id: number;
  playerId: number;
  file: UploadFile;
  points: string;
  bonusPoints: string;
}

export const CorrectMission = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [messageApi, contextHolder] = message.useMessage();
  const players = useAppSelector(selectPlayers).players;
  const auth = useAppSelector(selectAuth);
  const [pendingMissions, setPendingMissions] = useState<Mission[]>();
  const [pendingTapGames, setPendingTapGames] = useState<ITapGame[]>();
  const [secretMissions, setSecretMissions] = useState<SecretMission[]>();
  const [secretMissionForms, setSecretMissionForms] = useState<ICorrectSecretMissionValues[]>([]);
  const [pendingTapGameForms, setPendingTapGameForms] = useState<ICorrectTapGameValues[]>([]);
  const [collapseItems, setCollapseItems] = useState<CollapseProps["items"]>();
  const [comments, setComments] = useState<MissionComment[]>();
  const showSuccess = () => {
    messageApi.open({
      type: 'success',
      content: 'Uppdrag rättat',
      duration: 3,
    });
  };

  const handleInputChange = (index: number, field: string, value: any) => {
    const updatedForms = [...secretMissionForms];
    updatedForms[index] = { ...updatedForms[index], [field]: value };
    setSecretMissionForms(updatedForms);
  };
  const handleTapGameInputChange = (index: number, field: string, value: any) => {
    const updatedForms = [...pendingTapGameForms];
    updatedForms[index] = { ...updatedForms[index], [field]: value };
    setPendingTapGameForms(updatedForms);
  };
  const handleUpload = (index: number, file: any) => {
    const updatedForms = [...secretMissionForms];
    updatedForms[index] = { ...updatedForms[index], file };
    setSecretMissionForms(updatedForms);
    return false; // Prevent auto upload
  };

  const handleTapGameUpload = (index: number, file: any) => {
    const updatedForms = [...pendingTapGameForms];
    updatedForms[index] = { ...updatedForms[index], file };
    setPendingTapGameForms(updatedForms);
    return false; // Prevent auto upload
  };
  useEffect(() => {
    const forms: ICorrectSecretMissionValues[] = secretMissions?.map(x => ({
      missionId: x.id!,
      playerId: x.foundBy,
      points: "",
      bonusPoints: "",
      file: null as any
    })) || [];
    setSecretMissionForms(forms);
  }, [secretMissions]);

  useEffect(() => {
    const forms: ICorrectTapGameValues[] = pendingTapGames?.map(x => ({
      id: x.id!,
      playerId: x.player,
      points: "",
      bonusPoints: "",
      file: null as any
    })) || [];
    setPendingTapGameForms(forms);
  }, [pendingTapGames]);

  const PendingMissions = async () => {
    dispatch(setLoading());
    const pendingMissions = await GetPendingMissions();
    dispatch(resetLoading());
    if (pendingMissions) {
      setPendingMissions(pendingMissions);
    }
  }

  const SecretMissions = async () => {
    dispatch(setLoading());
    const secretMissions = await GetSecretMissions();
    dispatch(resetLoading());
    if (secretMissions) {
      setSecretMissions(secretMissions.data.filter((x: SecretMission) => !x.isCorrected));
    }
  }

  const fetchComments = async () => {
    dispatch(setLoading());
    try {
      const data = await GetMissionComments();
      setComments(data.data);
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(resetLoading());
    }
  }

  const TapGames = async () => {
    dispatch(setLoading());
    try {
      const data = await GetPendingTapGames();
      setPendingTapGames(data);
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(resetLoading());
    }
  }

  useEffect(() => {
    fetchComments();
    PendingMissions();
    SecretMissions();
    TapGames();
  }, []);

  useEffect(() => {
    const collapseItems: CollapseProps["items"] = [];

    players.length > 0 && pendingMissions && pendingMissions.length > 0 && pendingMissions.map(x => {
      collapseItems.push({
        key: x.id!.toString(),
        label: x.title,
        children: <CorrectMissionTemplate mission={x} playersList={players} PendingMissions={PendingMissions} showSuccess={showSuccess} comments={comments} />
      })
    });
    setCollapseItems(collapseItems);
  }, [pendingMissions, players]);

  const handleSecretSubmit = async () => {
    const request: ICorrectSecretMissionValues[] = secretMissionForms.map(x => ({
      missionId: x.missionId,
      playerId: x.playerId,
      points: x.points,
      bonusPoints: x.bonusPoints,
      file: x.file
    }));

    dispatch(setLoading());
    const data = await CorrectSecretMission(request);
    dispatch(resetLoading());

    if (data?.data) {
      request.map(x => {
        const notification = getNotificationTemplate(data.data!, players.map((x: Player) => x.id!), auth.user!, players, undefined, players.find(p => p.id === x.playerId));
        notification && CreateNotification(notification);
      })
      
      showSuccess();
      SecretMissions();
    }
  }

  const handleTapGameSubmit = async () => {
    const request: ICorrectTapGameValues[] = pendingTapGameForms.map(x => ({
      id: x.id,
      playerId: x.playerId,
      points: x.points,
      bonusPoints: x.bonusPoints,
      file: x.file
    }));

    dispatch(setLoading());
    const data = await CorrectTapGame(request);
    dispatch(resetLoading());

    if (data?.data) {
      request.map(x => {
        const notification = getNotificationTemplate(data.data!, players.map((x: Player) => x.id!), auth.user!, players, pendingTapGames!.find(g => g.player === x.playerId)!.level - 1, players.find(p => p.id === x.playerId));
        notification && CreateNotification(notification);
      })

      showSuccess();
      TapGames();
    }
  }

  return (
    <div className="content-box">
      {contextHolder}
      <h1>Rätta uppdrag</h1>
      <div className="missions-container">
        {
          pendingMissions && pendingMissions.length > 0 ?
            <Collapse items={collapseItems} expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />} expandIconPosition="end" />
            : <></>
        }

        {secretMissions && <h1>Hemliga uppdrag</h1>}

        <div className="hand-in-form">
          <Form
            name="correct-secret-mission-form"
            id="correct-secret-mission-form"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            style={{ maxWidth: 600 }}
            initialValues={{ remember: true }}
            onFinish={handleSecretSubmit}
            autoComplete="correct-mission-form"
          >
            {secretMissions && secretMissionForms.length > 0 &&
              secretMissions.map((x, index) => (
                <>
                  <p>{x.missionKey === "header" ? "Miniatyrlänk i header" : ""}</p>
                  <p>Hittad av: {players.find(y => y.id === x.foundBy)?.username}</p>
                  <Form.Item key={x.foundBy}>
                    <Form.Item
                      label="Ledtråd"
                      rules={[{ required: true, message: "Ladda upp ledtråd" }]}
                    >
                      <Upload
                        listType="picture"
                        maxCount={1}
                        beforeUpload={file => handleUpload(index, file)}
                        fileList={secretMissionForms[index].file ? [secretMissionForms[index].file] : []}
                      >
                        <Button type="default" icon={<UploadOutlined />}>Ladda upp ledtråd</Button>
                      </Upload>
                    </Form.Item>
                    <Form.Item
                      label="Poäng"
                      rules={[{ required: true, message: "Ange poäng" }]}
                    >
                      <Input
                        name="correct-secret-mission-form-points"
                        id="correct-secret-mission-form-points"
                        autoComplete="correct-secret-mission-form-points"
                        value={secretMissionForms[index].points}
                        onChange={e => handleInputChange(index, 'points', e.target.value)}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Bonuspoäng"
                      rules={[{ required: true, message: "Ange poäng" }]}
                    >
                      <Input
                        name="correct-secret-mission-form-bonus-points"
                        id="correct-secret-mission-form-bonus-points"
                        autoComplete="correct-secret-mission-form-bonus-points"
                        value={secretMissionForms[index].bonusPoints}
                        onChange={e => handleInputChange(index, 'bonusPoints', e.target.value)}
                      />
                    </Form.Item>
                  </Form.Item>
                </>
              ))}
            <Form.Item wrapperCol={{ offset: 8, span: 16 }} className="submit-container">
              {secretMissions && secretMissionForms.length > 0 &&
                <Button type="primary" htmlType="submit">
                  Spara
                </Button>
              }
            </Form.Item>
          </Form>
              


        </div>

        {pendingTapGames && pendingTapGames.length > 0 && <h1>Klarade klickspel</h1>}

        <div className="hand-in-form">
          <Form
            name="correct-tapgame-form"
            id="correct-tapgame-form"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            style={{ maxWidth: 600 }}
            initialValues={{ remember: true }}
            onFinish={handleTapGameSubmit}
            autoComplete="correct-tapgame-form"
          >
            {pendingTapGames && pendingTapGameForms.length > 0 &&
              pendingTapGames.map((x, index) => (
                <>
                  <p>{players.find(y => y.id === x.player)?.username} klarade nivå {x.level - 1}</p>
                  <Form.Item key={x.player}>
                    <Form.Item
                      label="Ledtråd"
                      rules={[{ required: true, message: "Ladda upp ledtråd" }]}
                    >
                      <Upload
                        listType="picture"
                        maxCount={1}
                        beforeUpload={file => handleTapGameUpload(index, file)}
                        fileList={pendingTapGameForms[index].file ? [pendingTapGameForms[index].file] : []}
                      >
                        <Button type="default" icon={<UploadOutlined />}>Ladda upp ledtråd</Button>
                      </Upload>
                    </Form.Item>
                    <Form.Item
                      label="Poäng"
                      rules={[{ required: true, message: "Ange poäng" }]}
                    >
                      <Input
                        name="correct-tapgame-form-points"
                        id="correct-tapgame-form-points"
                        autoComplete="correct-tapgame-form-points"
                        value={pendingTapGameForms[index].points}
                        onChange={e => handleTapGameInputChange(index, 'points', e.target.value)}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Bonuspoäng"
                      rules={[{ required: true, message: "Ange poäng" }]}
                    >
                      <Input
                        name="correct-tapgame-form-bonus-points"
                        id="correct-tapgame-form-bonus-points"
                        autoComplete="correct-tapgame-form-bonus-points"
                        value={pendingTapGameForms[index].bonusPoints}
                        onChange={e => handleTapGameInputChange(index, 'bonusPoints', e.target.value)}
                      />
                    </Form.Item>
                  </Form.Item>
                </>
              ))}
            <Form.Item wrapperCol={{ offset: 8, span: 16 }} className="submit-container">
              {pendingTapGames && pendingTapGames.length > 0 &&
                <Button type="primary" htmlType="submit">
                  Spara
                </Button>
              }
            </Form.Item>
          </Form>
              


        </div>
      </div>
    </div>
  )
};