import { Route, Routes } from 'react-router-dom';
import { useAppSelector } from './store/hooks';
import { selectAuth } from './features/user/authSlice';
import { Spin } from 'antd';
import { DefaultLayout } from './components/layouts/DefaultLayout';
import { UserHomePage } from './components/UserHomePage';
import { LoginPage } from './components/LoginPage';
import { NotFoundPage } from './components/NotFoundPage';
import { UserLayout } from './components/layouts/UserLayout';
import { AdminLayout } from './components/layouts/AdminLayout';
import { AdminHomePage } from './components/AdminHomePage';
import { HomePage } from './components/HomePage';
import UserMissionsPage from './components/userpages/UserMissionsPage';
import UserCluesPage from './components/userpages/UserCluesPage';
import ScoresPage from './components/userpages/ScoresPage';
import PackingListPage from './components/userpages/PackingListPage';
import GuessDestinationPage from './components/userpages/GuessDestinationPage';
import AssignMissionPage from './components/adminpages/AssignMissionPage';
import { CreateUser } from './components/adminpages/CreateUser';
import { CorrectMission } from './components/adminpages/CorrectMission';
import { Faq } from './components/userpages/Faq';
import { CreateMissionTemplatePage } from './components/adminpages/CreateMissionTemplatePage';
import { selectPlayers } from './features/players/playersSlice';
import { useEffect } from 'react';
import TapGame from './components/userpages/TapGame';
import { Maffia } from './components/templates/Maffia';

const App = () => {
  const auth = useAppSelector(selectAuth);
  const players = useAppSelector(selectPlayers);

  return (
    <div style={{ height: "95%" }}>
      {
        auth.user ? (
          !auth.user.RoleClaim.includes("HasRoleAdd") ? (
            <div className="app">
              <Spin spinning={auth.status == "loading"} style={{ height: "100%" }} rootClassName="ur-spinner">
                <Routes>
                  <Route path="/" element={<UserLayout />}>
                    <Route index element={<UserHomePage />} />
                    <Route path="/maffia" element={<Maffia />} />
                    <Route path="/tap-game" element={<TapGame />} />
                    <Route path="/my-missions" element={<UserMissionsPage />} />
                    <Route path="/my-clues" element={<UserCluesPage />} />
                    <Route path="/scores" element={<ScoresPage />} />
                    <Route path="/packing-list" element={<PackingListPage />} />
                    <Route path="/guess-destination" element={<GuessDestinationPage />} />
                    <Route path="/faq" element={<Faq role={auth.user.RoleClaim} />} />
                    <Route path="*" element={<NotFoundPage />} />
                  </Route>
                </Routes>
              </Spin>
            </div>
          ) : (
            <div className="app">
              <Spin spinning={auth.status == "loading"} style={{ height: "100%" }}>
                <Routes>
                  <Route path="/" element={<AdminLayout {...auth.user} />}>
                    <Route index element={<AdminHomePage />} />
                    <Route path="/maffia" element={<Maffia />} />
                    <Route path="/assign-mission" element={<AssignMissionPage />} />
                    <Route path="/create-mission-template" element={<CreateMissionTemplatePage />} />
                    <Route path="/create-user" element={<CreateUser />} />
                    <Route path="/correct-missions" element={<CorrectMission />} />
                    <Route path="/scores" element={<ScoresPage />} />
                    <Route path="/packing-list" element={<PackingListPage />} />
                    <Route path="/faq" element={<Faq role={auth.user.RoleClaim} />} />
                    <Route path="*" element={<NotFoundPage />} />
                  </Route>
                </Routes>
              </Spin>
            </div>
          )
        ) :
          <div className="app">
            <Spin spinning={auth.status == "loading"}>
              <Routes>
                <Route path="/" element={<DefaultLayout />}>
                  <Route index element={<HomePage />} />
                  <Route path="login" element={<LoginPage />} />
                  <Route path="*" element={<NotFoundPage />} />
                </Route>
              </Routes>
            </Spin>
          </div>
      }
    </div>
  );
};

export default App;
