import { useEffect, useState } from "react";
import { Collapse, CollapseProps, message } from "antd";
import { Mission } from "../../store/types";
import { GetMissionTemplates } from "../../actions/MissionsActions";
import { CaretRightOutlined } from '@ant-design/icons';
import { AssignMissionTemplate } from "../templates/AssignMissionTemplate";

const AssignMissionPage = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [missionTemplates, setMissionTemplates] = useState<Mission[]>();
  const [missionTemplateCollapseItems, setMissionTemplateCollapseItems] = useState<CollapseProps["items"]>();

  const showSuccess = () => {
    messageApi.open({
      type: 'success',
      content: 'Uppdrag tilldelat',
      duration: 3,
    });
  };
  const fetchTemplates = async () => {
    const templates = await GetMissionTemplates();
    setMissionTemplates(templates.data);
  };

  useEffect(() => {
    fetchTemplates();
  }, []);

  useEffect(() => {
    const items: CollapseProps["items"] = [];

    missionTemplates?.map(x => {
      items.push({
        key: x.id!,
        label: x.title,
        children: <AssignMissionTemplate mission={x} onAssigned={(response: string) => handleAssigned(response)} />
      })
    });

    setMissionTemplateCollapseItems(items);
  }, [missionTemplates]);

  const handleAssigned = (success: string) => {
    if (success !== "") {
      showSuccess();
      fetchTemplates();
    }
  }
  return (
    <div className="content-box">
      {contextHolder}
      <h1>Tilldela uppdrag</h1>
      <div className="mission-form">
        {
          <Collapse items={missionTemplateCollapseItems} expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />} expandIconPosition="end" />
        }
      </div>
    </div>
  )
};

export default AssignMissionPage;