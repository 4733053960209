import axiosInstance from "../api/axios";
import { iAppResponse } from "../store/appResponse";
import { Clue } from "../store/types";

export const GetCluesByUser = async (playerId: number) => {
  try {
    const response = await axiosInstance.get<iAppResponse<Clue[]>>(`/api/clues/GetCluesByUser/${playerId}`);
    
    if (!response.data) {
      throw new Error('Failed to fetch clues');
    }

    return response.data.data as Clue[];
  } catch (ex) {
    console.error(ex);
    return undefined;
  }
}