import { Table, TableProps, Tabs, TabsProps } from "antd";
import { useEffect, useState } from "react";
import { GetPoints } from "../../actions/PointsActions";
import { resetLoading, selectAuth, setLoading } from "../../features/user/authSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { Player, Point, PointsTableProps } from "../../store/types";
import Decimal from 'decimal.js';
import { TrophyFilled } from '@ant-design/icons';
import { selectPlayers } from "../../features/players/playersSlice";

const ScoresPage = () => {
  const auth = useAppSelector(selectAuth);
  const players = useAppSelector(selectPlayers).players;
  const dispatch = useAppDispatch();
  const [pointsList, setPointsList] = useState<Point[]>([]);
  const [currentPlayerPoints, setCurrentPlayerPoints] = useState<Point[]>([])
  const [tabItems, setTabItems] = useState<TabsProps["items"]>([]);
  const [playersList, setPlayers] = useState<Player[]>([]);

  const onChange = (key: string) => {
    console.log("");
  };

  const getPlayerTotalPoints = () => {
    let totalPlayerPoints: Decimal = new Decimal(0.000000000);

    currentPlayerPoints.forEach(x => {
      const pointsDecimal = new Decimal(x.points);
      const bonusDecimal = new Decimal(x.bonusPoints);
      totalPlayerPoints = totalPlayerPoints.plus(pointsDecimal.plus(bonusDecimal));
    });

    const totalPointsString = totalPlayerPoints.toFixed(2);
    return totalPointsString;
  }

  const getTableData = () => {
    const table: PointsTableProps[] = [];

    playersList.map((x, index) => {
      const playerPoints = pointsList.filter(y => y.playerId === x.id);
      let totalPlayerPoints: Decimal = new Decimal(0.000000000);
      playerPoints && playerPoints.forEach(z => {
        const pointsDecimal = new Decimal(z.points);
        const bonusDecimal = new Decimal(z.bonusPoints);
        totalPlayerPoints = totalPlayerPoints.plus(pointsDecimal.plus(bonusDecimal));
      });
      const totalPointsString = totalPlayerPoints.toFixed(2);
      table.push({
        playerName: x.username,
        points: totalPlayerPoints,
        pointsString: totalPointsString
      })
    });

    table.sort((a, b) => b.points.comparedTo(a.points));

    table.map((x, index) => {
      x.place = (index + 1).toString() + "."
    });
    
    return table;
  }

  const arrangePlayerPoints = () => {
    const points = currentPlayerPoints;
    let returnArray: Point[] = [];
    points && points.forEach((x, index) => {
      if (index < 2) {
        returnArray.push({
          playerId: x.playerId,
          points: new Decimal(x.points).toFixed(2),
          bonusPoints: new Decimal(x.bonusPoints).toFixed(2)
        });
      } else {
        returnArray.push({
          playerId: x.playerId,
          points: new Decimal(x.points).toFixed(index > 6 ? index : index + 1),
          bonusPoints: new Decimal(x.bonusPoints).toFixed(index > 6 ? index : index + 1)
        });
      }
    });

    return returnArray;
  }
  const setupTabItems = () => {
    const playerTableData: Point[] = arrangePlayerPoints();
    const totalPlayerData: PointsTableProps[] = getTableData();
    const playerTableColumns: TableProps<Point>["columns"] = [
      {
        title: "Poäng",
        dataIndex: "points",
        key: "points"
      },
      {
        title: "Bonuspoäng",
        dataIndex: "bonusPoints",
        key: "bonusPoints"
      }
    ];

    const tableColumns: TableProps<PointsTableProps>["columns"] = [
      {
        title: <TrophyFilled />,
        dataIndex: "place",
        key: "place",
        className: "trophy-column"
      },
      {
        title: "Namn",
        dataIndex: "playerName",
        key: "playerName"
      },
      {
        title: "Poäng",
        dataIndex: "pointsString",
        key: "pointsString"
      }
    ]
    const tabs: TabsProps["items"] = [
      {
        key: "1",
        label: "Mina poäng",
        children: <>
          <Table
            columns={playerTableColumns}
            dataSource={playerTableData}
            pagination={false}
          />
          <h2>Total: {getPlayerTotalPoints()}</h2>
        </>
      },
      {
        key: "2",
        label: "Tabell",
        children: <Table
          columns={tableColumns}
          dataSource={totalPlayerData}
          pagination={false}
        />
      }
    ]
    setTabItems(tabs);
  }

  const FetchPoints = async () => {
    dispatch(setLoading());
    const points = await GetPoints();
    if (points && players) {
      dispatch(resetLoading());
      setPointsList(points);
      setPlayers(players);
    } else {
      dispatch(resetLoading());
    }
  };
  useEffect(() => {
    FetchPoints();
  }, []);

  useEffect(() => {
    if (pointsList.length > 0 && auth.user) {
      const playerPoints = pointsList.filter(x => x.playerId === parseInt(auth.user!.PleyerId));
      setCurrentPlayerPoints(playerPoints);
    }
  }, [auth.user, pointsList]);

  useEffect(() => {
    setupTabItems();
  }, [pointsList, currentPlayerPoints, playersList]);
  return (
    <div className="content-box">
      <h1>Poängställning</h1>
      <p>Poängen man tjänar in genom att slutföra uppdrag kan komma väl till pass under resan. Nedan ser du dina poäng och var i tabellen du befinner dig.</p>
      <Tabs defaultActiveKey="1" items={tabItems} onChange={onChange} />
    </div>
  )
};
export default ScoresPage;
