import { useState, useEffect } from 'react';
import { Mission } from '../../store/types';
import { GetCountdownTimer } from '../../actions/CountDownTimerActions';

// Helper function to format time
const formatTime = (seconds: number) => {
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor(seconds % 60);
  return `${h.toString().padStart(2, '0')}:${m.toString().padStart(2, '0')}:${s.toString().padStart(2, '0')}`;
};

const CountdownTimer = ({ mission }: { mission: Mission }) => {
  const [secondsLeft, setSecondsLeft] = useState<number>(0);

  useEffect(() => {
    // Function to fetch the mission's end time from the server
    const fetchEndTime = async () => {
      try {
        const response = await GetCountdownTimer(mission.id!);
        if (response && response.data) {
          // Assume response.data.endTime is in UTC format or a standard time format
          const endTime = new Date(response.data.endTime).getTime();
          const currentTime = new Date().getTime();

          // Calculate remaining time in seconds
          const timeDiffInSeconds = Math.floor((endTime - currentTime) / 1000);
          setSecondsLeft(timeDiffInSeconds > 0 ? timeDiffInSeconds : 0);
        }
      } catch (error) {
        console.error('Error fetching countdown:', error);
      }
    };

    // Fetch the end time initially
    fetchEndTime();

    // Set an interval to update the remaining time every second
    const intervalId = setInterval(() => {
      setSecondsLeft((prevSecondsLeft) => {
        if (prevSecondsLeft > 0) {
          return prevSecondsLeft - 1;
        } else {
          clearInterval(intervalId); // Stop the timer when it reaches zero
          return 0;
        }
      });
    }, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [mission.id]); // Re-run effect if mission ID changes

  return (
    <div>
      <h2>Tid</h2>
      <h2>{formatTime(secondsLeft)}</h2>
    </div>
  );
};

export default CountdownTimer;
