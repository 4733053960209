import { Button, Checkbox, Collapse, CollapseProps, Divider, Form, FormInstance, Input, Radio, Slider, Upload, UploadFile } from "antd"
import { UploadOutlined } from '@ant-design/icons';
import { IMissionTemplate, Mission } from "../../store/types";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store/store";
import React from "react";
import { CaretRightOutlined } from '@ant-design/icons';
import { RadioChangeEvent } from "antd/es/radio";
import { resetLoading, setLoading } from "../../features/user/authSlice";
import { AddMission } from "../../actions/MissionsActions";
import TextArea from "antd/es/input/TextArea";

type ICreateMissionTemplateValues = {
  title: string;
  description: string;
  adminUploadFile?: UploadFile[];
  adminUploadChecked: boolean;
  assignedToType: number;
  userUploadChecked: boolean;
  userFileMaxCount: number;
  hasTimeLimit: boolean;
  timeInHours: number;
}

const defaultTemplateValues: ICreateMissionTemplateValues = {
  title: "",
  description: "",
  adminUploadChecked: false,
  assignedToType: 0,
  userUploadChecked: false,
  userFileMaxCount: 1,
  hasTimeLimit: false,
  timeInHours: 0
}
export const CreateMissionTemplate = ({ template, onSave }: { template: IMissionTemplate, onSave: (success: boolean) => void }) => {
  const dispatch = useDispatch<AppDispatch>();
  const formRef = React.useRef<FormInstance>(null);
  const [isAdminUploadChecked, setIsAdminUploadChecked] = useState<boolean>(false);
  const [isUserUploadChecked, setIsUserUploadChecked] = useState<boolean>(false);
  const [adminFileList, setAdminFileList] = useState<UploadFile[]>([]);
  const [isTimeLimitChecked, setIsTimeLimitChecked] = useState<boolean>(false);
  const [templatePreview, setTemplatePreview] = useState<CollapseProps["items"]>([{
    children: <div className="collapse-item-child"></div>
  }]);
  const [assignedTypeValue, setAssignedTypeValue] = useState<number>();
  const [hours, setHours] = useState<number>(0);

  const handleAdminFileChange = ({ fileList }: { fileList: UploadFile[] }) => {
    setAdminFileList(fileList);
  };
  const handleAssignedTypeChanged = (event: RadioChangeEvent) => {
    setAssignedTypeValue(event.target.value);
  }

  const handleHourChanged = (value: number) => {
    setHours(value);
  };

  const handleSubmit = async (values: ICreateMissionTemplateValues) => {
    const mission: Mission = {
      missionType: 0,
      title: values.title,
      description: values.description,
      assignedToType: values.assignedToType,
      accomplished: false,
      pending: false,
      template: true,
      upload: adminFileList[0]?.originFileObj,
      userUpload: values.userUploadChecked,
      hasTimeLimit: values.hasTimeLimit,
      timeInHours: values.hasTimeLimit ? values.timeInHours : 0,
      playerOrder: false
    }
    dispatch(setLoading());
    const data = await AddMission(mission, mission.upload);
    if (data?.isSucceed) {
      dispatch(resetLoading());
      onSave(data.isSucceed);
    }
    dispatch(resetLoading());
    formRef.current?.resetFields();
    setAdminFileList([]);
    updatePreview(defaultTemplateValues, defaultTemplateValues);
  }

  const renderTextWithLinks = (text: string, hyperlinkText: string) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.split(' ').map((word, index) => {
      if (urlRegex.test(word)) {
        return (
          <>
            <br />
            <br />
            <a key={index} href={word.trim()} target="_blank" rel="noopener noreferrer">
              {hyperlinkText}
            </a>
            <br />
            <br />
          </>
        );
      }
      return <span key={index}>{word} </span>;
    });
  };

  const updatePreview = (changedValues: Partial<ICreateMissionTemplateValues>, allValues: ICreateMissionTemplateValues) => {
    const templateProps: CollapseProps["items"] = [{
      label: allValues.title,
      children: <div className="collapse-item-child">
        <p>{allValues.description !== "" && renderTextWithLinks(allValues.description, "Spela här")}</p>
        <div className="collapse-item-image">
          {
            allValues.adminUploadChecked && allValues.adminUploadFile && allValues.adminUploadFile.length > 0 &&
            allValues.adminUploadFile.map(x => {
              if (x.status !== "removed") {
                const url = URL.createObjectURL(x.originFileObj! as File);
                if (x.type?.includes("audio")) {
                  return (
                    <div className="video-wrapper">
                      <audio controls>
                        <source src={url} type={x.type} />
                        <p>Ljudet kan inte spelas upp, ladda ner det <a href={url}>här.</a></p>
                      </audio>
                    </div>
                  )
                } else if (x.type?.includes("video")) {
                  return (
                    <div className="video-wrapper">
                      <video width="100%" controls>
                        <source src={url} type={x.type} />
                        <p>Videon kan inte spelas upp, ladda ner den <a href={url}>här.</a></p>
                      </video>
                    </div>
                  )
                } else if (x.type?.includes("image")) {
                  return <img src={url} />
                } else {
                  <a href={url}>Ladda ner fil</a>
                }
              }
            })
          }
        </div>
        <div className="hand-in-form">
          <Divider style={{ borderColor: "#cfb193" }} dashed />
          <h4>Svara på uppdraget</h4>
          {
            allValues.userUploadChecked ?
              <Upload
                name="file"
                listType="picture"
                maxCount={allValues.userFileMaxCount}
                beforeUpload={() => false}
              >
                <Button type="default" disabled={true} icon={<UploadOutlined />}>Ladda upp fil</Button>
              </Upload>
              : <></>
          }
          <h3>Lämna kommentar</h3>
          <div className="user-comment-wrapper">
            <TextArea className="user-comment">
            </TextArea>
          </div>
          {
            allValues.hasTimeLimit ?
              <h1>03:13:20</h1>
              :
              <></>
          }
          <Button disabled={true} type="default" onClick={() => console.log("Skickas in...")} style={{ marginTop: "15px" }} >Skicka</Button>
        </div>
      </div>
    }];
    setTemplatePreview(templateProps);
  }

  return (
    <>
      <h2>Ny mall</h2>
      {
        template &&
        <Form
          name="create-mission-template-form"
          id="create-mission-template-form"
          style={{ maxWidth: 600 }}
          initialValues={{ remember: true }}
          onFinish={handleSubmit}
          autoComplete="create-mission-template-form"
          ref={formRef}
          onValuesChange={updatePreview}
        >
          <Form.Item<ICreateMissionTemplateValues>
            label="Titel"
            name="title"
            rules={[{ required: true, message: "Ange uppdragets titel" }]}
          >
            <Input
              name="create-mission-template-form-title"
              id="create-mission-template-form-title"
              autoComplete="create-mission-template-form-title"
            />
          </Form.Item>
          <Form.Item<ICreateMissionTemplateValues>
            label="Beskrivning"
            name="description"
            rules={[{ required: true, message: "Ange uppdragsbeskrivning" }]}
          >
            <Input.TextArea
              name="create-mission-template-form-description"
              id="create-mission-template-form-description"
              autoComplete="create-mission-template-form-description"
            />
          </Form.Item>
          <Form.Item<ICreateMissionTemplateValues>
            name="adminUploadChecked"
            valuePropName="checked"
            initialValue={false}
          >
            <Checkbox
              checked={isAdminUploadChecked}
              onChange={() => setIsAdminUploadChecked(!isAdminUploadChecked)}
            >
              Filer i uppdragsbeskrivningen
            </Checkbox>
          </Form.Item>
          {
            isAdminUploadChecked &&
            <Form.Item<ICreateMissionTemplateValues>
              name="adminUploadFile"
              label="Filer"
              getValueFromEvent={(e) => e.fileList}
            >
              <Upload
                name="file"
                listType="picture"
                maxCount={1}
                beforeUpload={() => false}
                fileList={adminFileList}
                onChange={handleAdminFileChange}
              >
                <Button icon={<UploadOutlined />}>Ladda upp fil</Button>
              </Upload>
            </Form.Item>
          }
          <Form.Item<ICreateMissionTemplateValues>
            name="assignedToType"
            label="Hur ska uppdraget tilldelas?"
            initialValue={0}
          >
            <Radio.Group
              onChange={handleAssignedTypeChanged}
              defaultValue={0}
            >
              <Radio value={0}>En spelare</Radio>
              <Radio value={1}>Flera spelare</Radio>
              <Radio value={2}>Alla spelare</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item<ICreateMissionTemplateValues>
            name="userUploadChecked"
            valuePropName="checked"
            initialValue={false}
          >
            <Checkbox
              checked={isUserUploadChecked}
              onChange={() => setIsUserUploadChecked(!isUserUploadChecked)}
            >
              Filer i svaret
            </Checkbox>
          </Form.Item>
          <Form.Item<ICreateMissionTemplateValues>
            name="hasTimeLimit"
            valuePropName="checked"
            initialValue={false}
          >
            <Checkbox
              checked={isTimeLimitChecked}
              onChange={() => setIsTimeLimitChecked(!isTimeLimitChecked)}
            >
              Tidsbegränsning
            </Checkbox>
          </Form.Item>
          {
            isTimeLimitChecked ?
              <Form.Item<ICreateMissionTemplateValues>
                label="Tid i timmar"
                name="timeInHours"
                valuePropName={"value"}
                initialValue={30}
              >
                <Slider tooltip={{ open: true }} max={200} value={hours} onChange={(value: number) => handleHourChanged(value)} />
              </Form.Item>
              :
              <></>
          }
          <Form.Item wrapperCol={{ offset: 8, span: 16 }} className="submit-container">
            <Button type="primary" htmlType="submit">
              Spara
            </Button>
          </Form.Item>
        </Form>
      }
      <div className="mission-template-preview">
        <h2>Förhandsgranskning</h2>
        <Collapse items={templatePreview} defaultActiveKey={[0]} expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />} expandIconPosition="end" />
      </div>
    </>
  );
}