import { Button, Modal, Table } from "antd";
import { useEffect, useState } from "react";
import { GetTapGame, GetTapGameHighScores, TapGameSucceded } from "../../actions/TapGameActions";
import "../../App.scss";
import { selectPlayers } from "../../features/players/playersSlice";
import { resetLoading, selectAuth, setLoading } from "../../features/user/authSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { ITapGame, ITapGameHighScores, Player } from "../../store/types";

const goalScores = [
  {
    level: 1,
    goalScore: 400
  },
  {
    level: 2,
    goalScore: 460
  },
  {
    level: 3,
    goalScore: 520
  },
  {
    level: 4,
    goalScore: 550
  },
  {
    level: 5,
    goalScore: 580
  },
  {
    level: 6,
    goalScore: 600
  }
]

const gustavGoalScores = [
  {
    level: 1,
    goalScore: 700
  },
  {
    level: 2,
    goalScore: 750
  },
  {
    level: 3,
    goalScore: 780
  },
  {
    level: 4,
    goalScore: 800
  },
  {
    level: 5,
    goalScore: 820
  },
  {
    level: 6,
    goalScore: 840
  }
]
export interface HighScores {
  place: string;
  player: string;
  score: number;
}
const TapGame = () => {
  const dispatch = useAppDispatch();
  const [count, setCount] = useState<number>(0); // The count of taps
  const [timeLeft, setTimeLeft] = useState<number>(60); // Time remaining
  const [isGameActive, setIsGameActive] = useState<boolean>(false); // To track if the game is running
  const [animate, setAnimate] = useState<boolean>(false);
  const [game, setGame] = useState<ITapGame>();
  const [goalScore, setGoalScore] = useState<number>();
  const [highScores, setHighScores] = useState<HighScores[]>();
  const [gustavHighScores, setGustavHighScores] = useState<HighScores[]>();
  // const [succeded, setSucceded] = useState<boolean>(false);
  const [succededModalOpen, setSuccededModalOpen] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const auth = useAppSelector(selectAuth);
  const players = useAppSelector(selectPlayers);
  // Helper function to format time
  const formatTime = (seconds: number) => {
    // const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = Math.floor(seconds % 60);
    return `${m.toString().padStart(2, '0')}:${s.toString().padStart(2, '0')}`;
  };

  const GetGame = async () => {
    const game = await GetTapGame(parseInt(auth.user!.PleyerId));

    if (game) {
      setGame(game);
    }
  }
  const GetHighScores = async () => {
    const highScores = await GetTapGameHighScores();
    if (highScores) {
      const gustavPlayer = players.players.find(x => x.username === "Gurra");
      const josefPlayer = players.players.find(x => x.username === "Skuggan");

      const filteredHighScores = highScores.filter((x: ITapGameHighScores) => {
        const gustavId = players.players.find(p => p.username === gustavPlayer?.username)?.id;
        const josefId = players.players.find(p => p.username === josefPlayer?.username)?.id;
      
        // Filter out scores where the player is NOT Gustav or Josef
        return x.player !== gustavId && x.player !== josefId;
      });
      const gustavHighScores = highScores.filter((x: ITapGameHighScores) => {
        const gustavId = players.players.find(p => p.username === gustavPlayer?.username)?.id;
        const josefId = players.players.find(p => p.username === josefPlayer?.username)?.id;
      
        // Filter where the player is either Gustav or Josef
        return x.player === gustavId || x.player === josefId;
      });
      filteredHighScores.sort((a: ITapGameHighScores, b: ITapGameHighScores) => b.score - a.score);
      const score: HighScores[] = filteredHighScores.map((x: ITapGameHighScores, index: number) => {
        return {
          place: `${index + 1}.`,
          player: players.players.find(y => y.id === x.player)?.username,
          score: x.score
        }
      });
      const gustavScores: HighScores[] = gustavHighScores.map((x: ITapGameHighScores, index: number) => {
        return {
          place: `${index + 1}.`,
          player: players.players.find(p => p.id === x.player)?.username,
          score: x.score
        }
      })
      score && setHighScores(score);
      gustavScores && setGustavHighScores(gustavScores);
    }
  }

  useEffect(() => {
    if (game && players) {
      const player = players.players.find(x => x.id === game.player)?.username;

      const level = game.level;

      if (player === "Gurra" || player === "Skuggan") {
        level && setGoalScore(gustavGoalScores.find(x => x.level === level)?.goalScore);
      } else {
        level && setGoalScore(goalScores.find(x => x.level === level)?.goalScore);
      }
    }
  }, [game]);

  useEffect(() => {
    GetGame();
    GetHighScores();
  }, []);
  // Start the game
  const startGame = (): void => {
    setCount(0); // reset the tap count
    setTimeLeft(60); // reset the timer
    setIsGameActive(true); // set game as active
  };

  // Handle tap/click event
  const handleTap = (): void => {
    if (isGameActive) {
      setCount((prevCount) => prevCount + 1);
      setAnimate(true);
      setTimeout(() => setAnimate(false), 200);
    }
  };

  const handleOk = () => {
    setIsModalOpen(false);
  }

  const handleSuccededOk = () => {
    GetGame();
    GetHighScores();
    setSuccededModalOpen(false);
  }
  const handleGameEnd = async (score: number) => {
    if (goalScore && score < goalScore) {
      setIsModalOpen(true)
    } else {
      dispatch(setLoading());
      const data = game && await TapGameSucceded(game, score);
      if (data) {
        // setSucceded(true);
        setSuccededModalOpen(true);
        // GetGame();
      }
      dispatch(resetLoading());
    }
    setCount(0);
  };
  // Countdown timer logic
  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;
    if (isGameActive && timeLeft > 0) {
      timer = setTimeout(() => setTimeLeft((prevTime) => prevTime - 1), 1000);
    } else if (timeLeft === 0) {
      setIsGameActive(false); // End the game
      handleGameEnd(count)
    }

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [isGameActive, timeLeft]);

  return (
    <div className="content-box">
      <h1>Klickspelet</h1>
      <p>Uppdraget går ut på att du ska lyckas trycka på texten nedan så många gånger du kan. Nå målet för nivån och få poäng och ledtråd. Spelet startar när du trycker på "Start" första gången, fortsätt sedan att trycka på samma ställe. När du klarat nivån är det bara att invänta nästa.</p>
      {
        game && goalScore && !game.pending ?
          <div className="game">
            {isGameActive ? (
              <div className="game-header">
                <h1>{formatTime(timeLeft)}</h1>
                <h1>Tryck: {count}</h1>
              </div>
            ) : (

              <div className="game-header">
                <h1>{formatTime(60)}</h1>
                <h1>Tryck: {count}</h1>
                {/* <button onClick={startGame}>Start Game</button> */}
              </div>
            )}
            <div className="tap-game-wrapper">
              <p className={isGameActive ? animate ? "tap-game-press-active animate" : "tap-game-press-active" : "tap-game-press"} onClick={isGameActive ? handleTap : !isModalOpen ? startGame : (e) => e.preventDefault()} >
                {
                  isGameActive ? "KÖR!" : "Start"
                }
              </p>
            </div>
            <div className="tap-game-footer">
              <p>Nivå {game.level}</p>
              <p>Mål {goalScore}</p>
            </div>
            <Modal
              className="tap-game-modal"
              title="Du e inte schnabb! Försök igen."
              open={isModalOpen}
              closable={false}
              footer={[
                <Button key="ok" type="primary" onClick={handleOk}>
                  Ok
                </Button>,
              ]}
            >
            </Modal>
            <Modal
              className="tap-game-modal"
              title="Grattis!"
              open={succededModalOpen}
              closable={false}
              footer={[
                <Button key="ok" type="primary" onClick={handleSuccededOk}>
                  Ok
                </Button>,
              ]}
            >
              <p>Snart får du din ledtråd och dina poäng. När de har kommit kan du spela spelet igen på en högre nivå.</p>
            </Modal>
          </div>
          :
          <div className="tap-game-pending">
            <h1>Väntar på poäng och ledtråd..</h1>
          </div>
      }
      {
        highScores &&
        <div>
          <Table
            columns={[
              {
                title: "",
                dataIndex: "place",
                className: "tap-game-table-header",
                key: "place",
                onCell: () => { return { className: "tab-game-cell" } }
              },
              {
                title: "Topplista",
                dataIndex: "player",
                className: "tap-game-table-header-top",
                key: "player",
                onCell: () => { return { className: "tab-game-cell-player" } }
              },
              {
                title: "",
                dataIndex: "score",
                key: "score"
              }
            ]}
            dataSource={highScores}
            pagination={false}
            className="tap-game-table-wrapper"
          />
          <Table
            columns={[
              {
                title: "",
                dataIndex: "place",
                className: "tap-game-table-header",
                key: "place",
                onCell: () => { return { className: "tab-game-cell" } }
              },
              {
                title: "Topplista för dopade",
                dataIndex: "player",
                className: "tap-game-table-header-top",
                key: "player",
                onCell: () => { return { className: "tab-game-cell-player" } }
              },
              {
                title: "",
                dataIndex: "score",
                key: "score"
              }
            ]}
            dataSource={gustavHighScores}
            pagination={false}
            className="tap-game-table-wrapper-gustav"
          />
        </div>
      }
    </div>
  );
}

export default TapGame;