import { Collapse, CollapseProps, message, UploadFile } from "antd";
import { useEffect, useState } from "react";
import { GetMissionsByUser, HandInMission } from "../../actions/MissionsActions";
import { resetLoading, selectAuth, setLoading } from "../../features/user/authSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { Mission, MissionComment, MissionHandIn } from "../../store/types";
import { UserMissionTemplate } from "../templates/MissionTemplate";
import { CaretRightOutlined } from '@ant-design/icons';
import { getNotificationTemplate } from "../templates/NotificationTemplate";
import { selectPlayers } from "../../features/players/playersSlice";
import { CreateNotification } from "../../actions/NotificationActions";
import { GetMissionComments } from "../../actions/CommentActions";

const UserMissionsPage = () => {
  const auth = useAppSelector(selectAuth);
  const players = useAppSelector(selectPlayers);
  const dispatch = useAppDispatch();
  const [messageApi, contextHolder] = message.useMessage(); 
  const [missions, setMissions] = useState<Mission[]>();
  const [ongoingMissions, setOngoingMissions] = useState<CollapseProps["items"]>();
  const [accomplishedMissions, setAccomplishedMissions] = useState<CollapseProps["items"]>();
  const [pendingMissions, setPendingMissions] = useState<CollapseProps["items"]>();
  const [comments, setComments] = useState<MissionComment[]>();
  const showSuccess = () => {
    messageApi.open({
      type: 'success',
      content: 'Svar skickat',
      duration: 3,
    });
  }

  const handleSubmit = async (mission: Mission, fileList: UploadFile[], comment?: string) => {
    const handInMission: MissionHandIn = {
      playerId: auth.user!.PleyerId as unknown as number,
      missionId: mission.id!,
      upload: fileList[0]?.originFileObj,
      userComment: comment ? comment : ""
    };

    if (auth.user) {
      dispatch(setLoading());
      const data = await HandInMission(handInMission);

      if (data) {
        const notification = getNotificationTemplate(data.data!, mission.assignedTo!, auth.user, players.players);
        notification && CreateNotification(notification);
        dispatch(resetLoading());
        showSuccess();
        fetchMissions();
        fetchComments();
      } else {
        dispatch(resetLoading());
      }
    }
  };
  const setupCollapseItems = (missionsList: Mission[]) => {
    const ongoing: CollapseProps["items"] = [];
    const accomplished: CollapseProps["items"] = [];
    const pending: CollapseProps["items"] = [];

    missionsList.map(x => {
      x.accomplished ?
        accomplished.push({
          key: x.id!.toString(),
          label: x.title,
          children: <UserMissionTemplate mission={x} comments={comments} />
        })
      :
      x.pending ?
        pending.push({
          key: x.id!.toString(),
          label: x.title,
          children: <UserMissionTemplate mission={x} comments={comments} />
        })
      : !x.template ?
        ongoing.push({
          key: x.id!.toString(),
          label: x.title,
          children: <UserMissionTemplate mission={x} onHandIn={handleSubmit} comments={comments} />
        })
      : <></>
    });

    setOngoingMissions(ongoing);
    setAccomplishedMissions(accomplished);
    setPendingMissions(pending);
  };
  const fetchMissions = async () => {
    dispatch(setLoading());
    try {
      const data = await GetMissionsByUser(auth.user!.PleyerId as unknown as number);
      setMissions(data);
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(resetLoading());
    }
  }

  const fetchComments = async () => {
    dispatch(setLoading());
    try {
      const data = await GetMissionComments();
      setComments(data.data);
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(resetLoading());
    }
  }
  useEffect(() => {
    fetchComments();
    fetchMissions();
  }, []);

  useEffect(() => {
    missions && setupCollapseItems(missions);
  }, [missions]);


  return (
    <div className="content-box">
      {contextHolder}
      <h1>Mina uppdrag</h1>
      <p>Här kan du se dina pågående och avslutade uppdrag och skicka in dina svar</p>
      <div className="missions-container">
        <h2>Pågående uppdrag</h2>
        {
          ongoingMissions && ongoingMissions.length > 0 ? 
            <Collapse items={ongoingMissions} expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />} expandIconPosition="end" />
          :
            <p>Fler upppdrag kommer snart...</p>   
        }
        <h2>Uppdrag på rättning</h2>
        {
          pendingMissions && pendingMissions.length > 0 ? 
            <Collapse items={pendingMissions} expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />} expandIconPosition="end" />
          :
            <p>Skicka in uppdrag nu! Poäng och ledtrådar väntar på dig.</p>   
        }
        <h2>Avslutade uppdrag</h2>
        {
          accomplishedMissions && accomplishedMissions.length > 0 ? 
            <Collapse items={accomplishedMissions} expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />} expandIconPosition="end" />
          :
            <p>Inga avslutade uppdrag? Kötta!</p>   
        }
      </div>
    </div>
  )
};

export default UserMissionsPage;