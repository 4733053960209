import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Player } from '../../store/types';

interface CurrentPlayerState {
  player: Player;
};

const initialState: CurrentPlayerState = {
  player: {
    firstName: "",
    lastName: "",
    username: "",
  },
};

const currentPlayerSlice = createSlice({
  name: 'currentPlayer',
  initialState,
  reducers: {
    setCurrentPlayer(state, action: PayloadAction<Player>) {
      state.player = action.payload;
    },
  },
});

export const { setCurrentPlayer } = currentPlayerSlice.actions;

export default currentPlayerSlice.reducer;